























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import {
  drawBg,
  drawText,
  getStringWidth,
  splitData,
  isIntersect,
} from "@/utils/biaozhu";
import {
  GetBiaozhuOption,
  GetTuijianType,
  GetEntity,
  GetShuxing,
  GetSechemaList,
  GetRenwuAttr,
} from "@/request/mark";
import { AddShiti, GetShitiList } from "@/request/storehouse";
import { GetShijians } from "@/request/schema";
import { getCurrentTime } from "@/utils/base";
import { GetMedcaseEntityList, GetShijianEntityList } from "@/request/check";
import RelationSelect from "./relation-select.vue";
@Component({
  components: {
    RelationSelect,
  },
})
export default class Name extends Vue {
  @Prop()
  private type: any;
  @Prop()
  private parentData: any;
  @Watch("parentData.data", { immediate: true })
  private parentDataChange() {
    if (!this.parentData.content) {
      return;
    }
    this.data = this.parentData.content;
    this.biaozhuData = JSON.parse(JSON.stringify(this.parentData.data));
  }
  @Watch("ifCompose", { immediate: true })
  private ifComposeChange() {
    if (this.ifCompose) {
      this.gaoliangData = [];
      this.clearAll(7);
      this.clearAll(8);
    }
  }
  private showCankao: any = false; // 这个是用来看坐标的，开发时候打开，上线时关闭
  private myX: any = 0;
  private myY: any = 0;
  private dialogType: any = "新建"; // 弹框类型,新建或者编辑
  private data: any = "";
  private treeData: any = []; // 当前语义类型树
  private yuyileixingList: any[] = []; //语义类型可选的列表
  private guanxiList: any[] = []; //关系类型可选的列表
  private biaozhuData: any = [];
  private contentHeight: any = 400; // 标注内容区域高度，会实时计算
  private W: any = 0; // 内容区域宽度
  private fontSize: any = 16; // 文字大小
  private biaozhuFontSize: any = 12; // 文字大小
  private linePath: any = "";
  private allString: any = ""; // 所有原文内容字符串
  private strArrData: any = []; // 字符串数组，一个字符为一项
  private strPointArr: any = []; // 字符串数组，每一项是每个字的内容（文本，坐标之类的数据）
  private defaultLeft: any = 100; // 默认的左间距，给标记留点溢出空间
  private defaultRight: any = 100; // 默认的右间距，给标记留点溢出空间。页面有滚动条时会占据掉15左右，所以右边会比左边多给点
  // private defaultTop: any = 20; // 默认的行间距
  private defaultTop: any = 58; // 默认的行间距
  private startPoint: any = {}; // 鼠标按下的坐标
  private endPoint: any = {}; // 鼠标抬起的坐标
  private lineicon: any = "\n"; // 换行标识符
  private lineData: any = []; // 行上方间距，根据标记的内容多少自动计算
  private startIndex: any = 0; // 类型标注计算长度会用到
  private endIndex: any = 0; // 内容换行时会用到，类型标注计算长度会用到
  private biaozhuResult: any = []; // 处理后的标注的内容，处理过可以直接绘制的
  private oneLineBiaozhuH: any = 30; // 每行标注高度
  private drawing: any = false; // 是否正在绘制中,为了方便鼠标离开绘制区域时能够及时结束绘制
  private startDrawLine: any = false; // 是否在画关系标注
  private drawineData: any = {
    to: {},
    from: {},
    关系类型: {},
    备注: "",
  }; // 关系标注绘制过程中的数据
  private dialogData: any = {
    语义类型: {},
  };
  private currentSelectText: any = "";
  private ifShowBiaozhuLineDialog: any = false; // 是否打开新建语义关系标注
  private ifShowBiaozhuTextDialog: any = false; // 是否打开新建语义类型标注
  private yuyiData: any = [];
  private guanxiOptions: any = [];
  private optionsData: any = [];
  private options: any = [];
  private shuxingOptions: any = [];
  private ifDoubleClickLeft: any = 0; // 是否是双击左键
  private ifShowAddShiti: any = false;
  private clickData: any = {
    from: { data: {} },
    to: { data: {} },
  }; // 点击的类型标注或关系标注，编辑和删除的时候会用到,
  private defaultProps: any = {
    label: "域名称",
  };
  private guanxiProps: any = {
    label: "relation",
  };
  private shijianData: any = {};
  private ifShowAddShijian: any = false;
  private ifShowEdit: any = false;
  private ifShowEditShijian: any = false;
  private shijianTypes: any = [];
  private relations: any = [];
  private shijianRelations: any = {};
  private shitis: any = [];
  private zhenci: any = [
    "初诊",
    "二诊",
    "三诊",
    "四诊",
    "五诊",
    "六诊",
    "七诊",
    "八诊",
    "九诊",
    "十诊",
  ];
  private shitiRelationLeft: any = {}; // 外部编辑实体关系对应的实体数据。
  private gaoliangData: any = [];
  private ifShowZidingyiShiti: any = false;
  private zidingyiName: any = "";
  private ifShowAddZidingyiShiti: any = false;
  private zhuantiShuxingOptions: any = []; // 右侧添加专题属性的二级主题选项
  private tuijianData: any = []; // 语义类型推荐数据
  private get ifCompose() {
    if (this.$store.state.ifCompose) {
      // // 禁掉浏览器的选中文字效果
      // document.onselectstart = function () {
      //   return false;
      // };
      // 禁掉浏览器的点击右键出现菜单功能
      document.oncontextmenu = function () {
        return false;
      };
    } else {
      // document.onselectstart = function () {
      //   return true;
      // };
      document.oncontextmenu = function () {
        return true;
      };
    }
    return this.$store.state.ifCompose;
  }
  private ifShowTip(text: any) {
    if (!text) {
      return false;
    }
    if (this.parentData.content.includes(text)) {
      return false;
    } else {
      return true;
    }
  }
  private yijichange() {
    this.zhuantiShuxingOptions = [];
    this.dialogData["二级主题"] = "";
  }
  /**
   * @description 获取二级主题选项
   */
  private getErjiOptions(e?: any) {
    return new Promise((resolve, reject) => {
      this.zhuantiShuxingOptions = [];
      if (!this.dialogData["一级主题"]) {
        this.$message.warning("请先选择一级主题");
        reject();
        return;
      }
      const index = this.parentData["专题类型列表"].findIndex((val: any) => {
        return val["专题类型"] == this.dialogData["一级主题"];
      });
      if (index == -1) {
        reject();
        return;
      }
      const params: any = {
        params: {
          table_name: this.dialogData["一级主题"],
          id: this.parentData["专题类型列表"][index].entity_id,
          need_attr_split: true,
        },
      };
      GetShitiList(this, params)
        .then((res: any) => {
          this.zhuantiShuxingOptions = res.attributes;
          resolve(true);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  }
  private openAddshiti() {
    if (!this.dialogData["语义类型"] || !this.dialogData["语义类型"]._id) {
      this.$message.warning("请先选择语义类型");
      return;
    }
    this.ifShowZidingyiShiti = true;
    this.zidingyiName = "";
  }
  private saveAdd() {
    if (!this.zidingyiName) {
      this.$message.warning("请输入实体名称");
      return;
    }
    const params: any = {
      语义id: this.dialogData["语义类型"]._id,
      实体名称: this.zidingyiName,
      是否临时: true,
    };
    AddShiti(this, params).then((res: any) => {
      this.ifShowZidingyiShiti = false;
      //刷新选项
    });
  }
  private delShitiRelation(index: any) {
    this.dialogData["实体关系"].splice(index, 1);
    // 删除参考数组里面的数据
    // const delId: any = this.dialogData.relations[index].data.id;
    // if (delId) {
    //   const i: any = this.dialogData.arr.findIndex((ele: any) => {
    //     return ele.id == delId;
    //   });
    //   if (i != -1) {
    //     this.dialogData.arr.splice(i, 1);
    //   }
    // }
  }
  private ShitiRelationChange(data: any, index: any) {
    this.dialogData["实体关系"][index] = data;
    this.$forceUpdate();
  }
  // 右侧编辑实体，这里需要支持编辑实体名称
  private editOneShiti(item: any) {
    this.dialogData = JSON.parse(JSON.stringify(item));
    this.options = [];
    this.shuxingOptions = [];
    this.treeData = JSON.parse(JSON.stringify(this.yuyiData));
    this.ifShowAddZidingyiShiti = true;
    this.drawing = false;
    this.getEntityOption("", "");
    this.getShuxingOptions();
  }
  private getBiaozhuId(arr: any) {
    let id: any = null;
    if (arr.length == 0) {
      id = 1;
    } else {
      id = arr[arr.length - 1].id + 1;
    }
    return id;
  }
  // 外部组件触发修改实体名称
  private editShitiName(item: any) {
    const index = this.getIndex(item.id);
    item["更新人"] = this.$store.state.user;
    item["更新时间"] = getCurrentTime("yyyy-MM-dd HH:mm:ss");
    item.ifCustom = true;
    this.biaozhuData[index] = JSON.parse(JSON.stringify(item));
    const id: any = item.id;
    // 如果修改了需要删除当前语义类型标注和相关的关系标注
    const delIndex: any = this.getIndex(item.id, this.biaozhuResult);
    this.biaozhuResult[delIndex]["语义类型"] =
      this.biaozhuData[index]["语义类型"];
    this.biaozhuResult[delIndex]["关联实体"] =
      this.biaozhuData[index]["关联实体"];
    this.biaozhuResult[delIndex]["attrs"] = this.biaozhuData[index]["attrs"];
    this.biaozhuResult[delIndex]["备注"] = this.biaozhuData[index]["备注"];
    let delData: any = [this.biaozhuResult[delIndex]];
    for (var i = this.biaozhuData.length - 1; i >= 0; i--) {
      const item = this.biaozhuData[i];
      if (item.type == "line" && !item.ifCustom) {
        if (item.from.id == id || item.to.id == id) {
          const dIndex: any = this.getIndex(item.id, this.biaozhuResult);
          delData.push(this.biaozhuResult[dIndex]);
          this.biaozhuData.splice(i, 1);
        }
      }
    }
    this.clearOne(delData);
    // //并重绘语义类型标注
    // const drawArg = this.getDrawArg(this.biaozhuResult[delIndex]);
    // this.biaozhuResult[delIndex] = drawArg;
    // const newId: any = new Date().getTime();
    // this.drawOneBiaozhu([this.biaozhuResult[delIndex]], newId);
    this.ifShowBiaozhuTextDialog = false;
    this.$emit("changeBiaozhu", JSON.parse(JSON.stringify(this.biaozhuData)));
  }
  // 外部组件触发新增自定义实体
  private addZidingyiShiti(e: any) {
    this.dialogData = {
      ifCustom: true,
      type: "text",
      index: [],
      text: "",
      关联实体: {},
      attrs: [],
      语义类型: {},
      备注: "",
      id: null,
      一级主题: e["一级主题"],
      二级主题: "",
    };
    this.options = [];
    this.shuxingOptions = [];
    this.treeData = JSON.parse(JSON.stringify(this.yuyiData));
    this.ifShowAddZidingyiShiti = true;
    this.drawing = false;
  }
  private nameChange(e: any) {
    if (!this.dialogData.text) {
      return;
    }
    const params: any = {
      text: this.dialogData.text,
    };
    GetTuijianType(this, params).then((res: any) => {
      res._id = res.id;
      delete res.id;
      this.dialogData["语义类型"] = res;
      this.dialogData["关联实体"] = {};
      this.dialogData["attrs"] = [];
      this.getEntityOption(this.dialogData.text, "", "add");
    });
  }
  // 新增自定义实体确定
  private biaozhuZidingyiText() {
    if (!this.dialogData["text"]) {
      this.$message.warning("请输入实体名称");
      return;
    }
    if (!this.dialogData["一级主题"]) {
      this.$message.warning("请选择一级主题");
      return;
    }
    if (!this.dialogData["二级主题"]) {
      this.$message.warning("请选择二级主题");
      return;
    }
    if (!this.dialogData["语义类型"]._id) {
      this.$message.warning("请选择语义类型");
      return;
    }
    // 需要生成id（生成标注id）
    // this.dialogData.id = this.getBiaozhuId(this.biaozhuData);

    // 生成创建时间，更新时间，创建人，更新人。因为是新建创建时间和更新时间是一样的，创建人和更新人是一样的
    this.dialogData["更新人"] = this.$store.state.user;
    this.dialogData["更新时间"] = getCurrentTime("yyyy-MM-dd HH:mm:ss");
    if (!this.dialogData.id) {
      this.dialogData["创建人"] = this.$store.state.user;
      this.dialogData["创建时间"] = getCurrentTime("yyyy-MM-dd HH:mm:ss");
      this.dialogData.id = new Date().getTime();
      this.biaozhuData.push(JSON.parse(JSON.stringify(this.dialogData)));
    } else {
      let index: any = this.biaozhuData.findIndex((val: any) => {
        return val.id == this.dialogData.id;
      });
      if (this.dialogData.ifCustom) {
        if (index != -1) {
          this.biaozhuData[index] = JSON.parse(JSON.stringify(this.dialogData));
        }
      } else {
        this.dialogData.ifCustom = true;
        this.biaozhuData[index] = JSON.parse(JSON.stringify(this.dialogData));
        const id: any = this.dialogData.id;
        // 如果修改了需要删除当前语义类型标注和相关的关系标注
        const delIndex: any = this.getIndex(
          this.dialogData.id,
          this.biaozhuResult
        );
        this.biaozhuResult[delIndex]["语义类型"] =
          this.biaozhuData[index]["语义类型"];
        this.biaozhuResult[delIndex]["关联实体"] =
          this.biaozhuData[index]["关联实体"];
        this.biaozhuResult[delIndex]["attrs"] =
          this.biaozhuData[index]["attrs"];
        this.biaozhuResult[delIndex]["备注"] = this.biaozhuData[index]["备注"];
        let delData: any = [this.biaozhuResult[delIndex]];
        for (var i = this.biaozhuData.length - 1; i >= 0; i--) {
          const item = this.biaozhuData[i];
          if (item.type == "line" && !item.ifCustom) {
            if (item.from.id == id || item.to.id == id) {
              const dIndex: any = this.getIndex(item.id, this.biaozhuResult);
              delData.push(this.biaozhuResult[dIndex]);
              this.biaozhuData.splice(i, 1);
            }
          }
        }
        this.clearOne(delData);
      }
    }
    this.$emit("changeBiaozhu", JSON.parse(JSON.stringify(this.biaozhuData)));
    this.ifShowAddZidingyiShiti = false;
  }
  // 外部组件触发新增实体
  private addShiti(d: any) {
    this.dialogData = {
      ifCustom: true,
      type: "text",
      index: [],
      text: "",
      关联实体: {},
      attrs: [],
      一级主题: d["一级主题"],
      二级主题: d["二级主题"],
      语义类型: d["语义类型"],
      备注: "",
      id: null,
    };
    this.ifShowAddShiti = true;
  }
  private addShitiRelation() {
    this.dialogData["实体关系"].push({
      ifCustom: true,
      direction: "",
      data: {
        关系类型: {},
      },
      left: {},
      right: [],
      语义类型: {},
    });
    this.$forceUpdate();
  }
  // 外部组件新增实体保存
  private addShitiSave() {
    if (!this.dialogData["text"]) {
      this.$message.warning("请输入实体名称");
      return;
    }
    if (!this.dialogData["语义类型"]._id) {
      this.$message.warning("请选择语义类型");
      return;
    }
    // 需要生成id(标注id)
    // this.dialogData.id = this.getBiaozhuId(this.biaozhuData);

    // 生成创建时间，更新时间，创建人，更新人。因为是新建创建时间和更新时间是一样的，创建人和更新人是一样的
    this.dialogData["更新时间"] = getCurrentTime("yyyy-MM-dd HH:mm:ss");
    this.dialogData["更新人"] = this.$store.state.user;
    this.dialogData.id = new Date().getTime();
    this.dialogData["创建时间"] = getCurrentTime("yyyy-MM-dd HH:mm:ss");
    this.dialogData["创建人"] = this.$store.state.user;
    this.biaozhuData.push(JSON.parse(JSON.stringify(this.dialogData)));
    this.$emit("changeBiaozhu", JSON.parse(JSON.stringify(this.biaozhuData)));
    this.ifShowAddShiti = false;
  }
  // 外部编辑关系
  private editOne(item: any) {
    this.dialogData = JSON.parse(JSON.stringify(item));
    this.ifShowEdit = true;
  }
  /**
   * @description 有关联实体就返回关联实体，没有就是text
   * @param obj 需要过滤的元数据
   */
  private getText(obj: any) {
    if (!obj) {
      return "";
    }
    let text: any = "";
    if (!obj || !obj["关联实体"] || !obj["关联实体"].name) {
      text = obj.text;
    } else {
      text = obj["关联实体"].name;
    }
    return text;
  }
  private getDataFromIndex(id: any) {
    const index: any = this.biaozhuData.findIndex((ele: any) => {
      return ele.id == id;
    });
    return this.biaozhuData[index];
  }
  private editSave() {
    // 验证数据填写的完整性
    for (const item of this.dialogData["实体关系"]) {
      if (!item.direction) {
        this.$message.warning("您还有关系方向未选择，请选择");
        return;
      }
      if (!item.data["关系类型"] || !item.data["关系类型"].id) {
        this.$message.warning("您还有关系类型未填写，请填写完整");
        return;
      }
      if (!item["语义类型"] || !item["语义类型"]._id) {
        this.$message.warning("您还有语义类型未填写，请填写完整");
        return;
      }
      if (!item.right || item.right.length == 0) {
        this.$message.warning("您还有关系实体未选择，请选择");
        return;
      }
    }
    // 先删除所有自定义的关系，再重新建立新的自定义关系
    const allData = JSON.parse(JSON.stringify(this.biaozhuData));
    const customIds: any = [];
    this.dialogData.originRelations.forEach((one: any) => {
      if (one.ifCustom) {
        customIds.push(one.id);
      }
    });
    let resultArr: any = [];
    if (customIds.length == 0) {
      resultArr = allData;
    } else {
      resultArr = allData.filter(
        (item: any) => !customIds.includes(item.id as any)
      );
    }
    // 建立新的自定义关系
    this.dialogData["实体关系"].forEach((element: any) => {
      // 只需要创建自定义的
      if (element.ifCustom) {
        element.right.forEach((ele: any) => {
          // 生成关系
          let relationObj: any = {};
          // 修改方向数据，即from和to
          if (element.direction == "left") {
            // 左边to,右边from
            relationObj = {
              id: resultArr[resultArr.length - 1].id + 1,
              ifCustom: true,
              from: {
                id: ele.id,
              },
              to: {
                id: this.dialogData.id,
              },
              type: "line",
              关系类型: element.data["关系类型"],
              创建时间: getCurrentTime("yyyy-MM-dd HH:mm:ss"),
              更新时间: getCurrentTime("yyyy-MM-dd HH:mm:ss"),
              创建人: this.$store.state.user,
              更新人: this.$store.state.user,
              一级主题: this.dialogData["一级主题"],
              二级主题: this.dialogData["二级主题"],
              三级语义类型: this.dialogData["语义类型"].label,
            };
          } else if (element.direction == "right") {
            // 左边是from,右边是to
            relationObj = {
              id: resultArr[resultArr.length - 1].id + 1,
              ifCustom: true,
              from: {
                id: this.dialogData.id,
              },
              to: {
                id: ele.id,
              },
              type: "line",
              关系类型: element.data["关系类型"],
              创建时间: getCurrentTime("yyyy-MM-dd HH:mm:ss"),
              更新时间: getCurrentTime("yyyy-MM-dd HH:mm:ss"),
              创建人: this.$store.state.user,
              更新人: this.$store.state.user,
              一级主题: this.dialogData["一级主题"],
              二级主题: this.dialogData["二级主题"],
              三级语义类型: this.dialogData["语义类型"].label,
            };
          }
          if (!relationObj.from.id || !relationObj.to.id) {
            this.$message.warning("id缺失");
            return;
          }
          resultArr.push(relationObj);
        });
      }
    });
    this.dialogData.arr = resultArr;
    this.$emit("changeBiaozhu", JSON.parse(JSON.stringify(resultArr)));
    //
    this.ifShowEdit = false;
  }
  private getOneEntity(search: any, text: any) {
    return new Promise((resolve, reject) => {
      const params: any = {
        params: {
          kind: text,
          search: search,
        },
      };
      GetEntity(this, params)
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  }
  private openAddShijian(e: any) {
    this.shijianData = {};
    // 生成id(标注id)
    // if (this.biaozhuData.length == 0) {
    //   this.shijianData.id = 1;
    // } else {
    //   this.shijianData.id =
    //     this.biaozhuData[this.biaozhuData.length - 1].id + 1;
    // }
    this.shijianData.id = new Date().getTime();
    // 双击时的X坐标，也是事件标注绘制时的x坐标
    this.shijianData.drawTextX = e.offsetX;
    // 处理事的位置（相对位置）,根据偏移行距离来定位
    this.shijianData.position = {
      // 双击点的相对位置
      line: "", // 相对行数
      offset: "", // 偏移位置，如果是正数说明是在文本最后一行的下面，
    };
    // 首先判断是不是最底下
    if (e.offsetY > this.lineData[this.lineData.length - 1].top) {
      this.shijianData.position = {
        line: this.lineData.length,
        offset: e.offsetY - this.lineData[this.lineData.length - 1].top,
      };
    } else {
      this.lineData.forEach((ele: any, index: any) => {
        if (index == 0) {
          // 判断是不是最上面
          if (e.offsetY < ele.top) {
            this.shijianData.position = {
              line: 1,
              offset: e.offsetY - ele.top,
            };
          }
        } else {
          if (e.offsetY > this.lineData[index - 1].top && e.offsetY < ele.top) {
            this.shijianData.position = {
              line: ele.line,
              offset: e.offsetY - ele.top,
            };
          }
        }
      });
    }
    this.shijianData.type = "event";
    this.getShijianTypes();
    // 拦截，position里面的数据必须有，否则就不能添加事件
    if (!this.shijianData.position.line || !this.shijianData.position.offset) {
      return;
    }
    // 关闭事件类型的标注
    // this.ifShowAddShijian = true;
  }
  // 新增事件类型确定
  private addShijianSure() {
    if (
      !this.shijianData["事件类型"] ||
      !this.shijianData["事件类型"]["语义定义名称"]
    ) {
      this.$message.warning("请选择事件类型");
      return;
    }
    if (
      this.shijianData["事件类型"] &&
      this.shijianData["事件类型"]["语义定义名称"] == "诊次" &&
      !this.shijianData["诊次"]
    ) {
      this.$message.warning("请选择诊次");
      return;
    }
    if (!this.shijianData["事件关联实体"]) {
      this.$message.warning("请选择事件关联实体");
      return;
    }
    if (this.shijianData["事件关联实体"] == "新建") {
      if (!this.shijianData["事件名称"]) {
        this.$message.warning("请输入事件名称");
        return;
      }
    } else if (this.shijianData["事件关联实体"] == "关联现有实体") {
      if (!this.shijianData["关联实体"] || !this.shijianData["关联实体"].id) {
        this.$message.warning("请选择关联实体");
        return;
      }
    }
    this.shijianData["创建时间"] = getCurrentTime("yyyy-MM-dd HH:mm:ss");
    this.shijianData["更新时间"] = getCurrentTime("yyyy-MM-dd HH:mm:ss");
    this.shijianData["创建人"] = this.$store.state.user;
    this.shijianData["更新人"] = this.$store.state.user;
    // 因为事件标注的位置不能移动，所以每次添加事件就添加到最前面，这样保证渲染计算顺序时不会出现覆盖问题
    this.biaozhuData.push(JSON.parse(JSON.stringify(this.shijianData)));
    // this.biaozhuData.unshift(JSON.parse(JSON.stringify(this.shijianData)));
    const drawArg = this.getResultData(this.shijianData);
    this.biaozhuResult.push(drawArg);
    const newId: any = new Date().getTime();
    this.drawOneBiaozhu([drawArg], newId);
    this.ifShowAddShijian = false;
    this.$emit("changeBiaozhu", JSON.parse(JSON.stringify(this.biaozhuData)));
  }
  private openEditShijian() {
    let guanxiKeys: any = [];
    this.shijianData["事件类型"]["出现关系"].forEach((ele: any) => {
      guanxiKeys.push(ele["名称"]);
    });
    this.shijianData.guanxiKeys = guanxiKeys;
    this.remoteShitiMethod("");
    this.refreshGuanxi();
    this.ifShowEditShijian = true;
  }
  private refreshGuanxi() {
    this.shijianData.shijianRelations = {};
    this.shijianData.guanxiKeys.forEach((ele: any) => {
      this.shijianData.shijianRelations[ele] = [];
    });
    this.biaozhuResult.forEach((ele: any) => {
      // 只需要处理跟事件有关的语义类型标注，所以需要通过符合的关系里面去找
      if (ele.type == "line") {
        // from和to必须有一个是事件
        if (ele.from.type == "event" || ele.from.to == "event") {
          const eventD: any = ele.from.type == "event" ? ele.from : ele.to;
          const textD: any = ele.from.type == "event" ? ele.to : ele.from;
          // 该事件是点击事件
          if (eventD.id === this.shijianData.id) {
            // 判断是不是需要展示的语义类型
            let type = textD["语义类型"]["label"];
            const index = this.shijianData.guanxiKeys.indexOf(type);
            if (index != -1) {
              // 如果有关联实体显示的是关联实体，否则显示的是标注的文本
              let text: any = "";
              if (textD["关联实体"].id) {
                text = textD["关联实体"].name;
              } else {
                text = textD.text;
              }
              // 判断是否已经有了，也就是变相去重
              const i = this.shijianData.shijianRelations[type].findIndex(
                (v: any) => {
                  const val = v["关联实体"].id ? v["关联实体"].name : v.text;
                  return val === text;
                }
              );
              if (i == -1) {
                this.shijianData.shijianRelations[type].push(textD);
              }
            }
          }
        }
      }
    });
    this.$forceUpdate();
  }
  private editShijianSure() {
    if (
      !this.shijianData["事件类型"] ||
      !this.shijianData["事件类型"]["语义定义名称"]
    ) {
      this.$message.warning("请选择事件类型");
      return;
    }
    if (!this.shijianData["事件关联实体"]) {
      this.$message.warning("请选择事件关联实体");
      return;
    }
    if (this.shijianData["事件关联实体"] == "新建") {
      if (!this.shijianData["事件名称"]) {
        this.$message.warning("请输入事件名称");
        return;
      }
    } else if (this.shijianData["事件关联实体"] == "关联现有实体") {
      if (!this.shijianData["关联实体"] || !this.shijianData["关联实体"].id) {
        this.$message.warning("请选择关联实体");
        return;
      }
    }
    const obj = {
      drawTextX: this.shijianData.drawTextX,
      id: this.shijianData.id,
      position: this.shijianData.position,
      type: this.shijianData.type,
      事件关联实体: this.shijianData["事件关联实体"],
      事件名称: this.shijianData["事件名称"],
      事件类型: this.shijianData["事件类型"],
      关联实体: this.shijianData["关联实体"],
      创建时间: this.shijianData["创建时间"],
      创建人: this.shijianData["创建人"],
      更新时间: getCurrentTime("yyyy-MM-dd HH:mm:ss"),
      更新人: this.$store.state.user,
    };
    const i = this.biaozhuData.findIndex((v: any) => {
      return v.id === this.shijianData.id;
    });
    this.biaozhuData[i] = obj;
    // 编辑事件,需要先删除当前绘制的事件，再重新绘制一个
    const delIndex: any = this.getIndex(obj.id, this.biaozhuResult);
    this.biaozhuResult[delIndex]["事件关联实体"] = obj["事件关联实体"];
    this.biaozhuResult[delIndex]["事件名称"] = obj["事件名称"];
    this.biaozhuResult[delIndex]["事件类型"] = obj["事件类型"];
    this.biaozhuResult[delIndex]["关联实体"] = obj["关联实体"];
    this.clearOne([this.biaozhuResult[delIndex]]);
    // 根据关系类型重新绘图
    const drawArg = this.getDrawArg(this.biaozhuResult[delIndex]);
    this.biaozhuResult[delIndex] = drawArg;
    const id: any = new Date().getTime();
    this.drawOneBiaozhu([this.biaozhuResult[delIndex]], id);
    this.ifShowEditShijian = false;
    this.$emit("changeBiaozhu", JSON.parse(JSON.stringify(this.biaozhuData)));
  }
  private getShijianTypes() {
    return new Promise((resolve, reject) => {
      GetShijians(this)
        .then((res: any) => {
          this.shijianTypes = res;
          resolve(res);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  }
  private shijianChange() {
    this.shijianData["关联实体"] = {};
    this.shitis = [];
  }
  private changeGuanlianWay() {
    this.shijianData["关联实体"] = {};
    this.shijianData["事件名称"] = "";
  }
  private remoteShitiMethod(e: any) {
    const params: any = {
      params: {
        search: e,
        语义类型名称: this.shijianData["事件类型"]["语义定义名称"],
      },
    };
    GetShijianEntityList(this, params).then((res: any) => {
      this.shitis = res;
    });
  }
  private handleNodeClick(data: any, node: any) {
    this.dialogData["语义类型"] = data;
    this.dialogData["关联实体"] = {};
    this.dialogData["attrs"] = [];
  }
  /**
   * @description 判断鼠标是否在关系线上，如果是需要高亮显示
   */
  private isPointOnLine(point: any, lineSegments: any) {
    // 存储满足条件的线段
    const containingLineSegments: any = [];
    // 遍历所有线段
    for (const line of lineSegments) {
      const containingLineSegments = [];

      for (const segment of lineSegments) {
        // 只处理类型为 "line" 的线段
        if (
          segment.type === "line" &&
          segment.point1 &&
          segment.point2 &&
          segment.point3 &&
          segment.point4
        ) {
          // 检查点是否在线段 point1 到 point2 上
          if (
            this.isPointOnLineSegment(point, segment.point1, segment.point2)
          ) {
            containingLineSegments.push(segment);
          }

          // 检查点是否在线段 point2 到 point3 上
          if (
            segment.point3 &&
            this.isPointOnLineSegment(point, segment.point2, segment.point3)
          ) {
            containingLineSegments.push(segment);
          }

          // 检查点是否在线段 point3 到 point4 上
          if (
            segment.point4 &&
            this.isPointOnLineSegment(point, segment.point3, segment.point4)
          ) {
            containingLineSegments.push(segment);
          }
        }
      }

      return containingLineSegments;
    }

    // 返回包含点的线段数组
    return containingLineSegments;
  }
  // 辅助函数，用于检查点是否在线段上
  private isPointOnLineSegment(point: any, start: any, end: any) {
    const num: any = 3; // 3像素的偏移量，即在线的上下左右的偏移量都算在线上
    // 计算线段的方向向量
    const dx = end.x - start.x;
    const dy = end.y - start.y;

    // 检查点是否在线段边缘的3像素范围内
    const minX = Math.min(start.x, end.x) - num;
    const maxX = Math.max(start.x, end.x) + num;
    const minY = Math.min(start.y, end.y) - num;
    const maxY = Math.max(start.y, end.y) + num;

    if (
      point.x >= minX &&
      point.x <= maxX &&
      point.y >= minY &&
      point.y <= maxY
    ) {
      // 计算点到线段的垂直距离
      const perpendicularDistance = Math.abs(
        (dy * point.x - dx * point.y + dx * start.y - dy * start.x) /
          Math.sqrt(dx * dx + dy * dy)
      );

      // 如果垂直距离小于等于3像素，则认为点在线段上
      return perpendicularDistance <= num;
    }

    return false;
  }
  private handleGuanxiNodeClick(data: any) {
    if (data.id) {
      const obj = JSON.parse(JSON.stringify(data));
      obj._id = data.id;
      obj.label = data.relation;
      this.drawineData["关系类型"] = obj;
      (this.$refs.guanxiTree as any).setCurrentKey(obj);
    }
  }
  private handleMouseDown(e: any) {
    if (!this.ifCompose) {
      return;
    }
    if (
      this.ifShowBiaozhuLineDialog ||
      this.ifShowBiaozhuTextDialog ||
      this.ifShowAddShijian ||
      this.ifShowEditShijian ||
      this.ifShowEdit ||
      this.ifShowAddShiti ||
      this.ifShowAddZidingyiShiti
    ) {
      return;
    }
    // 重置数据
    this.startPoint = {};
    this.endPoint = {};
    this.drawing = false;
    // 获取点击位置的数据(text,event,line,str),str表示是点击在了内容文本上
    const target = this.getTarget(e);
    // 记录是不是双击，用在双击左键空白添加事件
    // ifDoubleClickLeft   1分钟内点击的次数
    if (this.ifDoubleClickLeft === 0) {
      // 第一次点击
      this.ifDoubleClickLeft = 1;
      this.clickData.from.which = e.which;
      this.clickData.to = { data: {} };
      this.clickData.from.position = target.position;
      this.clickData.from.data = target.data;
      setTimeout(() => {
        if (this.ifDoubleClickLeft == 1) {
          this.ifDoubleClickLeft = 0;
        }
      }, 1000);
    } else if (this.ifDoubleClickLeft == 1) {
      // 第二次点击
      this.ifDoubleClickLeft = 2;
      // 获取点击位置的数据(text,event,line,str),str表示是点击在了内容文本上
      this.clickData.to.which = e.which;
      this.clickData.to.position = target.position;
      this.clickData.to.data = target.data;
    }
    // e.which,1是左键，3是右键
    if (this.ifDoubleClickLeft == 1) {
      if (e.which == 1) {
        // console.log("单击左键");
        // 需要判断点击区域是否是类型标注或事件标注，如果是需要绘制关系标注的线
        if (target.data.type == "text" || target.data.type == "event") {
          if (!this.startDrawLine) {
            // 绘制关系开始
            this.startDrawLine = true;
            this.drawineData.from = target.data;
          } else {
            // 绘制关系结束
            this.linePath = "";
            this.startDrawLine = false;
            this.drawineData.to = target.data;
            if (this.drawineData.from.id == this.drawineData.to.id) {
              // 如果起点和终点都是同一个语义类型或事件，就不需要建立关系
              this.resetDrawData();
            }
            // else if (
            //   this.drawineData.from.type == "event" &&
            //   this.drawineData.to.type == "event"
            // ) {
            //   // 如果起点和终点都是事件，也不需要建立关系
            //   this.resetDrawData();
            // }
            else {
              this.dialogType = "新建";
              this.getTreeOption("关系类型");
            }
            return;
          }
        }
      }
    } else if (this.ifDoubleClickLeft == 2) {
      if (
        this.clickData.from.which === 1 &&
        this.clickData.to.which === 1 &&
        this.clickData.from.position == this.clickData.to.position
      ) {
        // console.log("双击左键");
        // console.log(this.clickData);
        // 如果双击
        if (!this.clickData.to.position) {
          // 双击的是空白，需要添加事件
          this.ifDoubleClickLeft = 0;
          this.openAddShijian(e);
        } else {
          if (this.clickData.from.data.id === this.clickData.to.data.id) {
            if (target.data.type == "text") {
              // 双击语义类型
              // 编辑语义类型
              this.dialogData = JSON.parse(JSON.stringify(target.data));
              this.openBiaozhuText();
            } else if (target.data.type == "event") {
              // 双击事件
              this.shijianData = JSON.parse(JSON.stringify(target.data));
              this.openEditShijian();
            } else if (target.data.type == "line") {
              // 双击关系
              // 编辑关系类型类型
              this.drawineData = JSON.parse(JSON.stringify(target.data));
              this.dialogType = "编辑";
              this.getTreeOption("关系类型");
            }
          }
        }
      }
      this.ifDoubleClickLeft = 0;
      this.linePath = "";
      this.drawing = false;
      this.startDrawLine = false;
      return;
    }
    // 需要判断点击区域是否是类型标注，如果是需要绘制关系标注的线
    let isInBiaozhu: any = false;
    const d: any = this.ifInBiaozhu(e);
    if (d) {
      if (this.clickData.to.which) {
        this.clickData.to.data = d;
      } else {
        this.clickData.from.data = d;
      }
      if (d.type === "text" || d.type === "event") {
        if (this.startDrawLine) {
          this.drawineData.to = d;
        } else {
          this.drawineData.from = d;
        }
        isInBiaozhu = true;
      }
    }

    // 记录开始绘制行为
    this.drawing = true;
    // 记录绘制起点坐标信息
    const line: any = this.getLineNum(e.offsetY);
    const pointY = this.lineData[line - 1].top - this.fontSize;
    this.startPoint = {
      x: e.offsetX,
      y: pointY,
      line: line,
      e: e,
    };
  }
  private handleMouseMove(e: any) {
    this.myX = e.offsetX;
    this.myY = e.offsetY;
    if (!this.ifCompose) {
      // 需要判断是不是在线上，如果在线上需要高亮线
      // 要检查的点
      const pointToCheck = { x: this.myX, y: this.myY };
      // 调用函数并输出结果
      const result = this.isPointOnLine(pointToCheck, this.biaozhuResult);
      if (result.length > 0) {
        this.gaoliangData = result;
        this.clearAll(7);
        this.clearAll(8);
        // 高亮
        const lineDom: any = document.getElementById("highlightDom");
        const pathDom = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "path"
        );
        // 画关系的线
        const item = this.gaoliangData[0];
        pathDom.setAttribute(
          "d",
          `M${item.point1.x},${item.point1.y} L ${item.point2.x},${item.point2.y} L ${item.point3.x},${item.point3.y} L ${item.point4.x},${item.point4.y}`
        );
        pathDom.setAttribute("stroke", "#20f526");
        pathDom.setAttribute("stroke-width", "4");
        pathDom.setAttribute("fill", "none");
        pathDom.setAttribute("marker-end", "url(#highlightArrow)");
        lineDom.appendChild(pathDom);
        // 高亮关系
        // 画高亮关系标注白底,16是字号
        const lineTextDom: any = document.getElementById("highlightLineDom");
        drawBg(
          lineTextDom,
          item.drawTextX - 12,
          item.drawTextY - 16,
          item.drawTextW + 14 + item.drawText.length * 6,
          16 + 4,
          "",
          "#fff",
          "4",
          "",
          "",
          "h-1"
        );
        // 画高亮关系标注文本
        const textDom = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "text"
        );
        textDom.setAttribute("x", item.drawTextX.toString());
        textDom.setAttribute("y", item.drawTextY.toString());
        textDom.setAttribute("font-size", "16");
        textDom.setAttribute("class", "cursorPoint");
        textDom.setAttribute("fill", "#20f526");
        textDom.setAttribute("font-weight", "bold"); // 设置文本加粗
        textDom.setAttribute("id", "h-2");
        textDom.innerHTML = item.drawText;
        lineTextDom.appendChild(textDom);
      } else {
        // 清除高亮
        // this.gaoliangData = [];
        // this.clearAll(7);
      }
      return;
    } else {
    }
    if (
      this.ifShowBiaozhuTextDialog ||
      this.ifShowBiaozhuLineDialog ||
      this.ifShowAddShijian ||
      this.ifShowEditShijian
    ) {
      return;
    }
    // 如果不是在绘制中不需要触发计算行为
    if (!this.drawing && !this.startDrawLine) {
      return;
    }
    if (this.startDrawLine) {
      // 画关系连接线
      this.linePath = this.computeLinePath(
        {
          x: this.drawineData.from.content[0][0],
          y: this.drawineData.from.content[1][0],
        },
        { x: e.offsetX, y: e.offsetY }
      );
    } else {
      // 当前点的坐标信息
      const line: any = this.getLineNum(e.offsetY);
      const pointY = this.lineData[line - 1].top - this.fontSize;
      this.endPoint = {
        x: e.offsetX,
        y: pointY,
        line: line,
        e: e,
      };
      // 给选中的文本加上背景色
      const dom: any = document.getElementById("select");
      // 清空绘制路径
      const child: any = dom.childNodes;
      // 如果从前面开始删会导致索引补位，只能删除一半，所以需要从后面开始删除
      if (child && child.length > 0) {
        for (var i = child.length - 1; i >= 0; i--) {
          dom.removeChild(child[i]);
        }
      }
      // 根据当前的起终点拿出符合的数据，并给这些数据绘制底色
      const arr: any = this.getSelectArr(this.startPoint, this.endPoint);
      arr.forEach((d: any) => {
        const x: any = d.startX - 2;
        const y: any = this.lineData[d.line - 1].top - this.fontSize + 2;
        const width: any = d.width + 2;
        drawBg(dom, x, y, width, this.fontSize + 2, "", "#409eff");
      });
    }
  }
  private handleMouseUp(e: any) {
    if (!this.ifCompose) {
      return;
    }
    if (
      this.ifShowBiaozhuTextDialog ||
      this.ifShowBiaozhuLineDialog ||
      this.ifShowAddShijian ||
      this.ifShowEditShijian
    ) {
      return;
    }
    if (e.which === 3) {
      const d: any = this.ifInBiaozhu(e);
      if (d) {
        this.$confirm("确定删除么？", "删除", {
          customClass: "commonConfirm",
        })
          .then((res: any) => {
            this.deleteOneBiaozhu(d);
            return;
          })
          .catch((res: any) => {
            this.drawing = false;
            this.startDrawLine = false;
            return;
          });

        // this.createContent();
      }
    }
    if (this.startDrawLine) {
      return;
    } else {
      // 清空绘制路径
      const dom: any = document.getElementById("select");
      const child: any = dom.childNodes;
      // 如果从前面开始删会导致索引补位，只能删除一半，所以需要从后面开始删除
      if (child && child.length > 0) {
        for (var i = child.length - 1; i >= 0; i--) {
          dom.removeChild(child[i]);
        }
      }
      // 如果不是在绘制中，且没有滑动不需要触发计算行为
      if (!this.drawing || !this.endPoint.x) {
        this.drawing = false;
        return;
      }
      this.drawing = false;
      this.endDrawing();
    }
  }
  private deleteOneBiaozhu(d: any) {
    // 删除，不重绘
    // 通过在biaozhuResult拿索引，删除当前的
    let index: any = this.getIndex(d.id);
    const delIndex: any = this.getIndex(d.id, this.biaozhuResult);
    let delData: any = [this.biaozhuResult[delIndex]];
    if (d.type === "text" || d.type === "event") {
      let id = this.biaozhuData[index].id;
      // 删除相关的关系标注
      this.biaozhuData.forEach((item: any, i: any) => {
        if (item.type == "line") {
          if (item.from.id == id || item.to.id == id) {
            const dIndex: any = this.getIndex(item.id, this.biaozhuResult);
            delData.push(this.biaozhuResult[dIndex]);
            this.biaozhuData.splice(i, 1);
          }
        }
      });
    }
    // 删除当前标注
    if (this.biaozhuData.length === 1) {
      this.biaozhuData = [];
    } else {
      this.biaozhuData.splice(index, 1);
    }
    this.clearOne(delData);
    this.$emit("changeBiaozhu", JSON.parse(JSON.stringify(this.biaozhuData)));
  }
  // 主题标签-自定义标注-删除整个语义类型对应的标注和关系,接收的参数是实体数组
  private deleteMoreBiaozhu(arr: any) {
    let delData: any = [];
    // 避免删除关系的时候找不到from或者to,需要先把所有关系删除，再统一删除实体
    // 删除关系
    arr.forEach((d: any) => {
      // 通过在biaozhuResult拿索引，删除当前的
      let index: any = this.getIndex(d.id);
      const delIndex: any = this.getIndex(d.id, this.biaozhuResult);
      if (d.type === "text") {
        let id = this.biaozhuData[index].id;
        // 删除相关的关系标注
        this.biaozhuData.forEach((item: any, i: any) => {
          if (item.type == "line") {
            if (item.from.id == id || item.to.id == id) {
              // 如果是通过右边主题标签里面添加的关系因为没有绘制路径，只需要清除数组里面的内容，无需清除绘制路径
              if (item.ifCustom) {
                this.biaozhuData.splice(i, 1);
              } else {
                const dIndex: any = this.getIndex(item.id, this.biaozhuResult);
                delData.push(this.biaozhuResult[dIndex]);
                this.biaozhuData.splice(i, 1);
              }
            }
          }
        });
      }
    });
    // 删除实体
    arr.forEach((d: any) => {
      let index: any = this.getIndex(d.id);
      const delIndex: any = this.getIndex(d.id, this.biaozhuResult);
      // 如果是通过右边主题标签里面添加的实体因为没有绘制路径，只需要清除数组里面的内容，无需清除绘制路径
      if (d.ifCustom) {
        this.biaozhuData.splice(index, 1);
      } else {
        delData.push(this.biaozhuResult[delIndex]);
        this.biaozhuData.splice(index, 1);
      }
    });
    this.clearOne(delData);
    this.$emit("changeBiaozhu", JSON.parse(JSON.stringify(this.biaozhuData)));
  }
  /**
   * @description 获取点击位置是文字，语义类型标注、关系标注还是事件标注
   */
  private getTarget(e: any) {
    let result: any = {
      position: "",
      data: {},
    };
    // 是否点击在文本上
    this.strPointArr.forEach((ele: any) => {
      if (
        e.offsetX > ele.x &&
        e.offsetX < ele.x + ele.width &&
        e.offsetY > ele.y - ele.width &&
        e.offsetY < ele.y
      ) {
        result.position = "str";
        result.data = ele;
      }
    });
    // 是否点击在标注上(语义类型和关系类型)
    this.biaozhuResult.forEach((ele: any) => {
      if (ele.type == "text" || ele.type == "line" || ele.type == "event") {
        if (
          e.offsetX > ele.content[0][0] &&
          e.offsetX < ele.content[0][1] &&
          e.offsetY > ele.content[1][0] &&
          e.offsetY < ele.content[1][1]
        ) {
          result.position = ele.type;
          result.data = ele;
        }
      }
    });
    return result;
  }
  private delOne(e: any) {
    // 先找出相关需要删除的实体数据
    let delArr: any = [];
    let ShowText: any = this.getText(e);
    this.biaozhuData.forEach((ele: any) => {
      // 只需要判断语义类型
      if (ele.type == "text") {
        const text: any = this.getText(ele);
        if (ShowText == text) {
          delArr.push(ele);
        }
      }
    });
    delArr.forEach((ele: any) => {
      const index = this.getIndex(ele.id);
      let id = this.biaozhuData[index].id;
      // 删除当前标注
      if (this.biaozhuData.length === 1) {
        this.biaozhuData = [];
      } else {
        this.biaozhuData.splice(index, 1);
      }
      // this.$emit("changeBiaozhu", JSON.parse(JSON.stringify(this.biaozhuData)));
      if (e.type === "text") {
        // 删除相关的关系标注
        for (var i = this.biaozhuData.length - 1; i >= 0; i--) {
          const item = this.biaozhuData[i];
          if (item.type == "line") {
            if (item.from.id == id || item.to.id == id) {
              this.biaozhuData.splice(i, 1);
            }
          }
        }
      }
    });
    this.$emit("changeBiaozhu", JSON.parse(JSON.stringify(this.biaozhuData)));
  }
  private changeClose(item: any, val: any) {
    item.close = val;
    this.$forceUpdate();
  }
  private searchYuyiOptions(e: any) {
    let search = "";
    if (e.target) {
      search = e.target.detail;
    } else {
      search = e;
    }
    const params: any = {
      params: {
        search: search,
        type: "语义类型",
      },
    };
    GetSechemaList(this, params).then((data: any) => {
      this.yuyileixingList = data;
    });
  }
  private leixingChange(item: any) {
    this.dialogData["语义类型"] = {
      _id: item._id,
      label: item.label,
      color: item.color,
    };
    this.dialogData["关联实体"] = {};
    this.dialogData["attrs"] = [];
  }
  private lineChange(e: any, item: any) {
    this.drawineData["关系类型"] = JSON.parse(JSON.stringify(item));
  }
  private searchGuanxiOptions(e: any) {
    let search = "";
    if (e.target) {
      search = e.target.detail;
    } else {
      search = e;
    }
    const params: any = {
      params: {
        search: search,
        type: "关系类型",
      },
    };
    GetSechemaList(this, params).then((data: any) => {
      this.guanxiList = data;
    });
  }
  private addShuxing(i: any) {
    this.dialogData.attrs[i].contents.push({
      属性值: this.dialogData.attrs[i].attribute,
      来源:
        this.parentData["来源"][0]["书籍名称"] +
        "-" +
        this.parentData["来源"][0]["章节名称"],
      目标值: "",
    });
  }
  private reduceShuxing(index: any, i: any) {
    this.dialogData.attrs[index].contents.splice(i, 1);
  }
  private change(e: any) {
    this.drawineData["关系类型"] = e;
    this.$forceUpdate();
  }
  private shitiChange(e?: any) {
    this.dialogData["attrs"] = [];
    this.getShuxingOptions(e);
  }
  private getShuxingOptions(e?: any) {
    const params: any = {
      params: {
        _id: this.dialogData["关联实体"].id,
      },
    };
    GetShuxing(this, params).then((res: any) => {
      this.shuxingOptions = res;
      this.$forceUpdate();
    });
  }
  private shuxingChange(e: any) {}
  private resetDrawData() {
    this.linePath = "";
    this.drawing = false;
    this.startDrawLine = false;
    this.clickData = { from: { data: {} }, to: { data: {} } };
    this.dialogData = {
      语义类型: {},
    };
    this.drawineData = {
      to: {},
      from: {},
      关系类型: {},
      备注: "",
    };
  }
  /**打开类型标注弹框 */
  private openBiaozhuText() {
    this.options = [];
    this.shuxingOptions = [];
    this.treeData = JSON.parse(JSON.stringify(this.yuyiData));
    if (!this.dialogData["语义类型"]._id) {
      const params: any = {
        text: this.dialogData.text,
      };
      GetTuijianType(this, params).then((res: any) => {
        res._id = res.id;
        delete res.id;
        this.dialogData["语义类型"] = res;
        this.dialogType = "新建";
        this.ifShowBiaozhuTextDialog = true;
        this.getEntityOption(this.dialogData.text, "", "add");
      });
    } else {
      this.dialogType = "编辑";
      this.$nextTick(() => {
        // 选中
        (this.$refs.yuyiTree as any).setCurrentKey(
          this.dialogData["语义类型"]._id
        );
      });
      this.ifShowBiaozhuTextDialog = true;
      if (this.dialogData["关联实体"].id) {
        this.getEntityOption("", this.dialogData["关联实体"].name);
        this.getShuxingOptions();
      }
    }
  }
  // 打开语义关系标注弹框
  private openBiaozhuLine() {
    this.ifShowBiaozhuLineDialog = true;
    this.treeData = JSON.parse(JSON.stringify(this.guanxiOptions));
    this.$forceUpdate();
    if (this.drawineData["关系类型"]._id) {
      this.$nextTick(() => {
        (this.$refs.guanxiTree as any).setCurrentKey(
          this.drawineData["关系类型"]._id
        );
      });
    }
  }
  private remoteMethod(val: any) {
    this.getEntityOption("", val);
  }
  private remoteShuxingMethod(val: any) {
    // this.getEntityOption("", val);
  }
  private getEntityOption(text: any, search: any, add?: any) {
    if (!this.dialogData["语义类型"] || !this.dialogData["语义类型"].label) {
      this.options = [];
      return;
    }
    let type: any = "";
    const params: any = {
      params: {
        text: text,
        kind: this.dialogData["语义类型"].label,
        search: search || "",
      },
    };
    GetEntity(this, params).then((res: any) => {
      this.options = res;
      if (add && res.length > 0) {
        this.dialogData["关联实体"] = res[0];
        this.getShuxingOptions();
      }
      this.$nextTick(() => {
        // 选中
        (this.$refs.yuyiTree as any).setCurrentKey(
          this.dialogData["语义类型"]._id
        );
      });
      // this.$forceUpdate();
    });
  }
  /**
   * @description 判断点击行为是否在语义类型中
   */
  private ifInBiaozhu(e: any) {
    let data = "";
    this.biaozhuResult.forEach((ele: any) => {
      if (ele.type == "text" || ele.type == "line" || ele.type == "event") {
        if (
          e.offsetX > ele.content[0][0] &&
          e.offsetX < ele.content[0][1] &&
          e.offsetY > ele.content[1][0] &&
          e.offsetY < ele.content[1][1]
        ) {
          data = ele;
        }
      }
    });
    return data;
  }
  /**
   * @description 判断点击行为是否在内容文本上
   */
  private ifInText(e: any) {
    let ifInner: any = false;
    // 是否点击在文本上
    this.strArrData.forEach((ele: any) => {
      if (
        e.offsetX > ele.startX &&
        e.offsetX < ele.startX + ele.width &&
        e.offsetY > this.lineData[ele.line - 1].top - ele.width &&
        e.offsetY < this.lineData[ele.line - 1].top + ele.width * 2
      ) {
        ifInner = true;
      }
    });
    // 是否点击在标注上(语义类型和关系类型)
    this.biaozhuResult.forEach((ele: any) => {
      if (ele.type == "text" || ele.type == "line" || ele.type == "event") {
        if (
          e.offsetX > ele.content[0][0] &&
          e.offsetX < ele.content[0][1] &&
          e.offsetY > ele.content[1][0] &&
          e.offsetY < ele.content[1][1]
        ) {
          ifInner = true;
        }
      }
    });
    return ifInner;
  }
  // 语义类型标注保存
  private biaozhuText() {
    if (this.dialogData["一级主题"] && !this.dialogData["二级主题"]) {
      this.$message.warning("请选择二级主题");
      return;
    }
    if (!this.dialogData["语义类型"]._id) {
      this.$message.warning("请选择语义类型");
      return;
    }
    const index = this.getIndex(this.dialogData.id);
    if (index == -1) {
      this.dialogData["创建时间"] = getCurrentTime("yyyy-MM-dd HH:mm:ss");
      this.dialogData["更新时间"] = getCurrentTime("yyyy-MM-dd HH:mm:ss");
      this.dialogData["创建人"] = this.$store.state.user;
      this.dialogData["更新人"] = this.$store.state.user;
      this.biaozhuData.push(JSON.parse(JSON.stringify(this.dialogData)));
      const drawArg = this.getResultData(this.dialogData);
      this.biaozhuResult.push(drawArg);
      const newId: any = new Date().getTime();
      this.drawOneBiaozhu([drawArg], newId);
    } else {
      this.dialogData["更新人"] = this.$store.state.user;
      this.dialogData["更新时间"] = getCurrentTime("yyyy-MM-dd HH:mm:ss");
      this.biaozhuData[index] = JSON.parse(JSON.stringify(this.dialogData));
      const id: any = this.dialogData.id;
      // 如果修改了需要删除当前语义类型标注和相关的关系标注,并重绘语义类型标注
      const delIndex: any = this.getIndex(
        this.dialogData.id,
        this.biaozhuResult
      );
      this.biaozhuResult[delIndex]["语义类型"] =
        this.biaozhuData[index]["语义类型"];
      this.biaozhuResult[delIndex]["关联实体"] =
        this.biaozhuData[index]["关联实体"];
      this.biaozhuResult[delIndex]["attrs"] = this.biaozhuData[index]["attrs"];
      this.biaozhuResult[delIndex]["备注"] = this.biaozhuData[index]["备注"];
      let delData: any = [this.biaozhuResult[delIndex]];
      for (var i = this.biaozhuData.length - 1; i >= 0; i--) {
        const item = this.biaozhuData[i];
        if (item.type == "line" && !item.ifCustom) {
          if (item.from.id == id || item.to.id == id) {
            const dIndex: any = this.getIndex(item.id, this.biaozhuResult);
            delData.push(this.biaozhuResult[dIndex]);
            this.biaozhuData.splice(i, 1);
          }
        }
      }
      this.clearOne(delData);
      //并重绘语义类型标注
      const drawArg = this.getDrawArg(this.biaozhuResult[delIndex]);
      this.biaozhuResult[delIndex] = drawArg;
      const newId: any = new Date().getTime();
      this.drawOneBiaozhu([this.biaozhuResult[delIndex]], newId);
    }
    this.ifShowBiaozhuTextDialog = false;
    this.$emit("changeBiaozhu", JSON.parse(JSON.stringify(this.biaozhuData)));
    //重新生成源数据
    // this.createContent();
  }
  /**
   * @description 新增标注时，因为不能重绘，所以需要根据新增的数据生成对应的biaozhuResult数据才能绘图
   */
  private getResultData(data: any) {
    let item: any = JSON.parse(JSON.stringify(data));
    item.sort = 1;
    let result: any = {};
    if (item.type == "text" && !item.ifCustom) {
      // 需要处理的字段content、drawText、drawTextW、drawTextX、drawTextY、
      // line、range、sort、startX、startY、width

      let obj: any = JSON.parse(JSON.stringify(item));
      // 计算出语义类型需要展示的文本，如果关联了实体，最多展示关联实体前5个字
      obj.drawText = item["语义类型"]["label"];
      if (item["关联实体"] && item["关联实体"].name) {
        if (item["关联实体"].name.length < 6) {
          obj.drawText += "(" + item["关联实体"].name + ")";
        } else {
          obj.drawText += "(" + item["关联实体"].name.substring(0, 5) + "...)";
        }
      }
      let range: any = []; // 整个标记的最大x轴范围
      // 计算标记的最大范围的起始坐标,如果选中文本比标记文本长则用选中文本的，否则用标记文本的，此处是用来判断是否会重合，从而标记加一行
      const w: any = getStringWidth(item.text, this.fontSize, this.lineicon); // 被选中文本的长度
      let w1: any =
        getStringWidth(obj.drawText, this.biaozhuFontSize, this.lineicon) + 4; // 语义类型标注的长度，4是大约标记边框的大小留余地
      const x: any = this.strArrData[item.index[0]].startX; //被选中文本的起始x坐标
      const x1: any = x + w / 2 - w1 / 2; // 语义类型标记的起始X坐标
      if (w < w1) {
        // 标记的最大范围的起始坐标用语义类型标注的
        range = [x1, x1 + w1];
      } else {
        // 标记的最大范围的起始坐标用选中文字的
        range = [x, x + w];
      }
      obj.width = w; // 所选文本的长度
      obj.startX = x; // 所选文本的起始X坐标
      obj.drawTextW = w1; // 语义类型标注的长度
      obj.drawTextX = x1; // 语义类型标注的起始X坐标
      obj.range = range; // 整个标记的最大x轴范围(原则是选中文本和标注文本取长的)

      const line: any = this.strArrData[item.index[0]].line;
      obj.line = line; //  所选文字行数,取的是选中文本的第一个文字所在行
      // 生成跟y轴有关的数据
      obj.startY = this.lineData[obj.line - 1].top;
      obj.drawTextY =
        obj.startY -
        this.biaozhuFontSize -
        (obj.sort - 1) * this.oneLineBiaozhuH -
        16; //  16是下面的括号
      obj.content = [
        // 用来判断是不是点击在了语义类型上面，生成的参考坐标
        //2,4是为标注不要挨太近
        [obj.drawTextX - 2, obj.drawTextX + obj.drawTextW + 4],
        [
          obj.drawTextY - this.biaozhuFontSize - 2,
          obj.drawTextY - this.biaozhuFontSize + this.fontSize + 4,
        ],
      ];
      result = obj;
    } else if (item.type == "event" && !item.ifCustom) {
      let obj: any = JSON.parse(JSON.stringify(item));
      let text: any = "";
      if (item["事件关联实体"] == "新建") {
        text = item["事件名称"];
      } else {
        text = item["关联实体"].name;
      }
      // 如果超过5个字，则只显示5个字
      if (text.length < 6) {
        obj.drawText = item["事件类型"]["语义定义名称"] + "(" + text + ")";
      } else {
        obj.drawText =
          item["事件类型"]["语义定义名称"] +
          "(" +
          text.substring(0, 5) +
          "...)";
      }
      // 计算最终需要绘制的事件标注文字的宽度
      obj.drawTextW = getStringWidth(
        obj.drawText,
        this.biaozhuFontSize,
        this.lineicon
      );
      obj.range = [obj.drawTextX, obj.drawTextX + obj.drawTextW]; // 标注的x轴范围
      obj.line = item.position.line;
      // 生成跟Y轴有关的数据
      // 这里加this.biaozhuFontSize是因为添加事件点击的坐标是文字左上角的y,而文字的绘制y是左下角，所以这里需要处理下
      obj.drawTextY =
        this.lineData[obj.position.line - 1].top +
        obj.position.offset +
        this.biaozhuFontSize;
      obj.content = [
        // 用来判断是不是点击在了语义类型上面，生成的参考坐标
        //2,4是为标注不要挨太近
        [obj.drawTextX - 2, obj.drawTextX + obj.drawTextW + 4],
        [
          obj.drawTextY - this.biaozhuFontSize - 2,
          obj.drawTextY - this.biaozhuFontSize + this.biaozhuFontSize + 4,
        ],
      ];
      result = obj;
    } else if (item.type == "line" && !item.ifCustom) {
      let obj: any = JSON.parse(JSON.stringify(item));
      // 关系的文本长度
      let text: any = "";
      if (obj["关系类型"]["名称"]) {
        text = obj["关系类型"]["名称"];
      } else if (obj["关系类型"]["label"]) {
        text = obj["关系类型"]["label"];
      } else if (obj["关系类型"]["relation"]) {
        text = obj["关系类型"]["relation"];
      }
      obj.drawText = text;
      obj.drawTextW = getStringWidth(
        obj.drawText,
        this.biaozhuFontSize,
        this.lineicon
      );
      // 根据from和to的id，完善from和to的数据
      const fromIndex: any = this.biaozhuResult.findIndex((val: any) => {
        return val.id == obj.from.id;
      });
      const toIndex: any = this.biaozhuResult.findIndex((val: any) => {
        return val.id == obj.to.id;
      });
      obj.from = this.biaozhuResult[fromIndex];
      obj.to = this.biaozhuResult[toIndex];
      // 生成关系标注所在行数
      // 哪个类型标注的行数偏小类型标注就展示在哪行
      if (obj.from.line > obj.to.line) {
        obj.line = obj.to.line;
        obj.sort = obj.to.sort + 1;
      } else {
        obj.line = obj.from.line;
        obj.sort = obj.from.sort + 1;
      }

      if (!obj.line) {
        obj.line = 1;
      }
      // 找出from和to哪个在左边，哪个在右边便于计算，关系标注的展示位置是在to和from的中间
      let left: any = {};
      let right: any = {};
      if (obj.from.drawTextX > obj.to.drawTextX) {
        left = obj.to;
        right = obj.from;
      } else {
        left = obj.from;
        right = obj.to;
      }
      // 根据left和right生成关系文本的x范围
      const center: any =
        left.drawTextX +
        (right.drawTextX + right.drawTextW - left.drawTextX) / 2; //left和right的中心点
      // 生成关系文本的开始x坐标
      obj.drawTextX = center - obj.drawTextW / 2;
      // 比较关系文本和from与to之间的距离哪个大，range使用大的做参考值,20是关系类型左右至少需要10的画线距离
      let range: any = [0, 0];
      if (
        right.drawTextX + right.drawTextW - left.drawTextX >
        obj.drawTextW + 20
      ) {
        range = [left.drawTextX, right.drawTextX + right.drawTextW];
      } else {
        range = [
          center - obj.drawTextW / 2 - 10,
          center + obj.drawTextW / 2 + 10,
        ];
      }
      obj.range = range;
      obj.lineW = range[1] - range[0];
      // 生成跟Y轴有关的数据
      obj.drawTextY =
        this.lineData[obj.line - 1].top -
        this.biaozhuFontSize -
        (obj.sort - 1) * this.oneLineBiaozhuH -
        16; //16是为了跟语义类型保持统一做的处理，语义类型16是下面的括号，在这里就是会留部分空白
      // 生成content
      obj.content = [
        // 用来判断是不是点击在了语义类型上面，生成的参考坐标
        //2,4是为标注不要挨太近
        [obj.drawTextX - 2, obj.drawTextX + obj.drawTextW + 4],
        [
          obj.drawTextY - this.biaozhuFontSize - 2,
          obj.drawTextY - this.biaozhuFontSize + this.biaozhuFontSize + 4,
        ],
      ];
      // 生成画关系线的四个点point1(from)，point2，point3，point4(to)
      // 需要判断from是在页面的左边还是to在页面的左边，因为线始终是左边标注的左点和右边标注的右点，结束位置会有箭头
      if (obj.from.drawTextX > obj.to.drawTextX) {
        // from在右边
        obj.point1 = {
          x: obj.from.drawTextX + obj.from.drawTextW,
          y: obj.from.drawTextY - this.biaozhuFontSize,
        };
        obj.point2 = {
          x: obj.range[1],
          y: obj.drawTextY - this.biaozhuFontSize / 2, //画在字的中间
        };
        obj.point3 = {
          x: obj.range[0],
          y: obj.drawTextY - this.biaozhuFontSize / 2, //画在字的中间
        };
        obj.point4 = {
          x: obj.to.drawTextX,
          y: obj.to.drawTextY - this.biaozhuFontSize,
        };
      } else {
        // to在右边
        obj.point1 = {
          x: obj.from.drawTextX,
          y: obj.from.drawTextY - this.biaozhuFontSize,
        };
        obj.point2 = {
          x: obj.range[0],
          y: obj.drawTextY - this.biaozhuFontSize / 2, //画在字的中间
        };
        obj.point3 = {
          x: obj.range[1],
          y: obj.drawTextY - this.biaozhuFontSize / 2, //画在字的中间
        };
        obj.point4 = {
          x: obj.to.drawTextX + obj.to.drawTextW,
          y: obj.to.drawTextY - this.biaozhuFontSize,
        };
      }
      result = obj;
    }
    return result;
  }
  private drawBiaozhu() {
    // 关闭弹框
    if (this.ifShowBiaozhuTextDialog) {
      this.ifShowBiaozhuTextDialog = false;
    }
    if (this.ifShowBiaozhuLineDialog) {
      this.ifShowBiaozhuLineDialog = false;
    }
    if (this.biaozhuResult.length == 0) {
      return;
    }
    // 画标注
    this.drawOneBiaozhu(this.biaozhuResult, 0);
  }
  /**
   * @description 画标注
   * arr 需要画图的数组，
   */
  private drawOneBiaozhu(arr: any, id?: any) {
    let idIndex: any = id;
    // 修改原有数据的id
    arr.forEach((item: any, itemIndex: any) => {
      if (item.type == "text") {
        item.drawId = "0-" + idIndex;
        item.drawLength = 4;
        const typedom: any = document.getElementById("typeDom");
        // 画选中文本的底色
        drawBg(
          typedom,
          item.startX,
          item.startY - this.biaozhuFontSize,
          item.width,
          this.biaozhuFontSize + 4,
          "",
          item["语义类型"].color,
          "",
          "",
          "",
          "0-" + idIndex
        );
        idIndex += 1;
        // 画类型标注底色
        drawBg(
          typedom,
          item.drawTextX - 2,
          item.drawTextY - this.biaozhuFontSize, //drawTextY是文字的坐标，是在文字的左下角，所以背景需要换成左上角时需要减去文字大小
          item.drawTextW + 2,
          this.biaozhuFontSize + 4,
          item["语义类型"].color,
          item["语义类型"].color,
          "4",
          "",
          "",
          "0-" + idIndex
        );
        idIndex += 1;
        // 画类型标注文本,16是留下画线的空间
        const textDom = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "text"
        );
        textDom.setAttribute("x", item.drawTextX.toString());
        textDom.setAttribute("y", item.drawTextY.toString());
        textDom.setAttribute("font-size", this.biaozhuFontSize);
        // textDom.setAttribute("fill", "red");
        // 加class
        textDom.setAttribute("class", "cursorPoint");
        textDom.setAttribute("id", "0-" + idIndex);
        textDom.innerHTML = item.drawText;
        typedom.appendChild(textDom);
        idIndex += 1;
        // 画类型标注括号
        const X: any = item.startX;
        const Y: any = item.drawTextY + 16 - 4; // 括号始终是在标注的下面位置，16是整个括号的高度(其中包括距离下面内容4)
        const C: any = item.startX + item.width / 2;
        const pathDom = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "path"
        );
        pathDom.setAttribute(
          "d",
          `M${X - 2},${Y + 2} L ${X},${Y} L ${C - 3},${Y} L ${C},${Y - 4} L ${
            C + 3
          },${Y} L ${X + item.width},${Y} L ${X + item.width + 2},${Y + 2}`
        );
        pathDom.setAttribute("stroke", item["语义类型"].color);
        pathDom.setAttribute("fill", "none");
        pathDom.setAttribute("id", "0-" + idIndex);
        typedom.appendChild(pathDom);
        idIndex += 1;
      } else if (item.type == "line") {
        item.drawId = "4-" + idIndex;
        item.drawLength = 1;
        const lineDom: any = document.getElementById("lineDom");
        const pathDom = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "path"
        );
        // 画关系的线
        pathDom.setAttribute(
          "d",
          `M${item.point1.x},${item.point1.y} L ${item.point2.x},${item.point2.y} L ${item.point3.x},${item.point3.y} L ${item.point4.x},${item.point4.y}`
        );
        pathDom.setAttribute("stroke", "#ccc");
        pathDom.setAttribute("fill", "none");
        pathDom.setAttribute("marker-end", "url(#arrow)");
        pathDom.setAttribute("id", "4-" + idIndex);
        lineDom.appendChild(pathDom);
        idIndex += 1;
        item.drawId2 = "5-" + idIndex;
        item.drawLength2 = 2;
        // 画关系标注白底
        const lineTextDom: any = document.getElementById("lineText");
        drawBg(
          lineTextDom,
          item.drawTextX - 2,
          item.drawTextY - this.biaozhuFontSize,
          item.drawTextW + 4,
          this.biaozhuFontSize + 4,
          "",
          "#fff",
          "4",
          "",
          "",
          "5-" + idIndex
        );
        idIndex += 1;
        // 画关系标注文本
        const textDom = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "text"
        );
        textDom.setAttribute("x", item.drawTextX.toString());
        textDom.setAttribute("y", item.drawTextY.toString());
        textDom.setAttribute("font-size", this.biaozhuFontSize);
        textDom.setAttribute("class", "cursorPoint");
        textDom.setAttribute("id", "5-" + idIndex);
        textDom.innerHTML = item.drawText;
        lineTextDom.appendChild(textDom);
        idIndex += 1;
      } else if (item.type == "event") {
        item.drawId = "6-" + idIndex;
        item.drawLength = 2;
        const shijiandom: any = document.getElementById("shijianDom");
        // 画事件标注底色,16是留下画线的空间
        drawBg(
          shijiandom,
          item.drawTextX - 2,
          item.drawTextY - this.biaozhuFontSize,
          item.drawTextW + 4,
          this.biaozhuFontSize + 4,
          item["事件类型"].color,
          item["事件类型"].color,
          "0",
          { color: "#666", borderWidth: 2 },
          false,
          "6-" + idIndex
        );
        idIndex += 1;
        // 画事件标注文本,16是留下画线的空间
        const textDom = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "text"
        );
        textDom.setAttribute("x", item.drawTextX.toString());
        textDom.setAttribute("y", item.drawTextY.toString());
        textDom.setAttribute("font-size", this.biaozhuFontSize);
        // 加class
        textDom.setAttribute("class", "cursorPoint");
        textDom.setAttribute("id", "6-" + idIndex);
        textDom.innerHTML = item.drawText;
        shijiandom.appendChild(textDom);
        idIndex += 1;
      }
    });
  }
  // 关系类型标注保存
  private biaozhuLine() {
    if (!this.drawineData["关系类型"]._id) {
      this.$message.warning("请选择关系类型");
      return;
    }
    const obj: any = {
      type: "line",
      from: {
        id: "",
      },
      to: {
        id: "",
      },
      关系类型: this.drawineData["关系类型"],
      备注: this.drawineData["备注"],
      创建人: this.drawineData["创建人"],
      更新人: this.drawineData["更新人"],
      创建时间: this.drawineData["创建时间"],
      更新时间: this.drawineData["更新时间"],
      一级主题: "",
      二级主题: "",
      三级语义类型: "",
    };
    obj.from.id = this.drawineData.from.id;
    obj.to.id = this.drawineData.to.id;
    // 需要生成一级主题，二级主题和三级语义类型。关系的主题数据用from的
    // 另外from和to都在当前一级主题下(否则右侧关系显示不出来)
    if (
      this.drawineData.from["一级主题"] &&
      this.drawineData.from["二级主题"] &&
      this.drawineData.to["一级主题"] &&
      this.drawineData.to["二级主题"] &&
      this.drawineData.from["一级主题"] == this.drawineData.to["一级主题"]
    ) {
      obj["一级主题"] = this.drawineData.from["一级主题"];
      obj["二级主题"] = this.drawineData.from["二级主题"];
      obj["三级语义类型"] = this.drawineData.from["语义类型"].label;
    }
    if (this.drawineData.id) {
      obj.id = this.drawineData.id;
      const index = this.getIndex(this.drawineData.id);
      obj["更新人"] = this.$store.state.user;
      obj["更新时间"] = getCurrentTime("yyyy-MM-dd HH:mm:ss");
      this.biaozhuData[index] = obj;
      // 编辑关系,需要先删除当前绘制的关系，再重新绘制一个
      const delIndex: any = this.getIndex(obj.id, this.biaozhuResult);
      this.biaozhuResult[delIndex]["关系类型"] = obj["关系类型"];
      this.biaozhuResult[delIndex]["备注"] = obj["备注"];
      this.clearOne([this.biaozhuResult[delIndex]]);
      // 根据关系类型重新绘图
      const drawArg = this.getDrawArg(this.biaozhuResult[delIndex]);
      this.biaozhuResult[delIndex] = drawArg;
      const id: any = new Date().getTime();
      this.drawOneBiaozhu([this.biaozhuResult[delIndex]], id);
    } else {
      // 如果是新增需要判断重复，如果重复了不需要再添加一个
      let ifRepeat: any = false;
      this.biaozhuData.forEach((ele: any) => {
        if (
          ele.type == "line" &&
          ele["关系类型"]._id === obj["关系类型"]._id &&
          ele.to.id === obj.to.id &&
          ele.from.id === obj.from.id
        ) {
          ifRepeat = true;
        }
      });
      if (ifRepeat) {
        this.ifShowBiaozhuLineDialog = false;
        this.drawineData = {
          to: {},
          from: {},
          关系类型: {},
          备注: "",
        };
        return;
      }
      obj.id = this.biaozhuData[this.biaozhuData.length - 1].id + 1;
      obj["创建时间"] = getCurrentTime("yyyy-MM-dd HH:mm:ss");
      obj["更新时间"] = getCurrentTime("yyyy-MM-dd HH:mm:ss");
      obj["创建人"] = this.$store.state.user;
      obj["更新人"] = this.$store.state.user;
      this.biaozhuData.push(obj);
      const drawArg = this.getResultData(obj);
      this.biaozhuResult.push(drawArg);
      const newId: any = new Date().getTime();
      this.drawOneBiaozhu([drawArg], newId);
    }
    this.ifShowBiaozhuLineDialog = false;
    this.$emit("changeBiaozhu", JSON.parse(JSON.stringify(this.biaozhuData)));
    //重新生成源数据
    // this.createContent();
  }
  // 生成单个画图数据,编辑标注的时候会用到
  private getDrawArg(item: any) {
    let result: any = {};
    // 先处理出类型标注的内容
    if (item.type == "text" && !item.ifCustom) {
      let obj: any = JSON.parse(JSON.stringify(item));
      // 计算出语义类型需要展示的文本，如果关联了实体，最多展示关联实体前5个字
      obj.drawText = item["语义类型"]["label"];
      if (item["关联实体"] && item["关联实体"].name) {
        if (item["关联实体"].name.length < 6) {
          obj.drawText += "(" + item["关联实体"].name + ")";
        } else {
          obj.drawText += "(" + item["关联实体"].name.substring(0, 5) + "...)";
        }
      }
      let range: any = []; // 整个标记的最大x轴范围
      // 计算标记的最大范围的起始坐标,如果选中文本比标记文本长则用选中文本的，否则用标记文本的，此处是用来判断是否会重合，从而标记加一行
      const w: any = getStringWidth(
        item.text,
        this.biaozhuFontSize,
        this.lineicon
      ); // 被选中文本的长度
      let w1: any =
        getStringWidth(obj.drawText, this.biaozhuFontSize, this.lineicon) + 4; // 语义类型标注的长度，4是大约标记边框的大小留余地
      const x: any = this.strArrData[item.index[0]].startX; //被选中文本的起始x坐标
      const x1: any = x + w / 2 - w1 / 2; // 语义类型标记的起始X坐标
      if (w < w1) {
        // 标记的最大范围的起始坐标用语义类型标注的
        range = [x1, x1 + w1];
      } else {
        // 标记的最大范围的起始坐标用选中文字的
        range = [x, x + w];
      }
      obj.width = w; // 所选文本的长度
      obj.startX = x; // 所选文本的起始X坐标
      obj.drawTextW = w1; // 语义类型标注的长度
      obj.drawTextX = x1; // 语义类型标注的起始X坐标
      obj.range = range; // 整个标记的最大x轴范围(原则是选中文本和标注文本取长的)

      const line: any = this.strArrData[item.index[0]].line;
      obj.line = line; //  所选文字行数,取的是选中文本的第一个文字所在行
      result = obj;
    } else if (item.type == "event" && !item.ifCustom) {
      let obj: any = JSON.parse(JSON.stringify(item));
      let text: any = "";
      if (item["事件关联实体"] == "新建") {
        text = item["事件名称"];
      } else {
        text = item["关联实体"].name;
      }
      // 如果超过5个字，则只显示5个字
      if (text.length < 6) {
        obj.drawText = item["事件类型"]["语义定义名称"] + "(" + text + ")";
      } else {
        obj.drawText =
          item["事件类型"]["语义定义名称"] +
          "(" +
          text.substring(0, 5) +
          "...)";
      }
      // 计算最终需要绘制的事件标注文字的宽度
      obj.drawTextW = getStringWidth(
        obj.drawText,
        this.biaozhuFontSize,
        this.lineicon
      );
      obj.range = [obj.drawTextX, obj.drawTextX + obj.drawTextW]; // 标注的x轴范围
      obj.line = item.position.line;
      result = obj;
    } else if (item.type == "line" && !item.ifCustom) {
      let obj: any = JSON.parse(JSON.stringify(item));
      // 关系的文本长度
      let text: any = "";
      if (obj["关系类型"]["名称"]) {
        text = obj["关系类型"]["名称"];
      } else if (obj["关系类型"]["label"]) {
        text = obj["关系类型"]["label"];
      } else if (obj["关系类型"]["relation"]) {
        text = obj["关系类型"]["relation"];
      }
      obj.drawText = text;
      obj.drawTextW = getStringWidth(
        obj.drawText,
        this.biaozhuFontSize,
        this.lineicon
      );
      // 根据from和to的id，完善from和to的数据
      // obj.from = arr[this.getIndex(obj.from.id, arr)];
      // obj.to = arr[this.getIndex(obj.to.id, arr)];
      // 生成关系标注所在行数
      // 哪个类型标注的行数偏小类型标注就展示在哪行
      // if (obj.from.line > obj.to.line) {
      //   obj.line = obj.to.line;
      // } else {
      //   obj.line = obj.from.line;
      // }
      //
      if (!obj.line) {
        obj.line = 1;
      }
      // 找出from和to哪个在左边，哪个在右边便于计算，关系标注的展示位置是在to和from的中间
      let left: any = {};
      let right: any = {};
      if (obj.from.drawTextX > obj.to.drawTextX) {
        left = obj.to;
        right = obj.from;
      } else {
        left = obj.from;
        right = obj.to;
      }
      // 根据left和right生成关系文本的x范围
      const center: any =
        left.drawTextX +
        (right.drawTextX + right.drawTextW - left.drawTextX) / 2; //left和right的中心点
      // 生成关系文本的开始x坐标
      obj.drawTextX = center - obj.drawTextW / 2;
      // 比较关系文本和from与to之间的距离哪个大，range使用大的做参考值,20是关系类型左右至少需要10的画线距离
      let range: any = [0, 0];
      if (
        right.drawTextX + right.drawTextW - left.drawTextX >
        obj.drawTextW + 20
      ) {
        range = [left.drawTextX, right.drawTextX + right.drawTextW];
      } else {
        range = [
          center - obj.drawTextW / 2 - 10,
          center + obj.drawTextW / 2 + 10,
        ];
      }
      obj.range = range;
      obj.lineW = range[1] - range[0];

      result = obj;
    }
    return result;
  }
  private endDrawing() {
    // 判断当前数据是否超出标注字数限制
    const arr: any = this.getSelectArr(this.startPoint, this.endPoint);
    if (arr.length == 0) {
      return;
    }
    let strLength: any = 0;
    let hasHuanhang: any = false;
    this.currentSelectText = "";
    // 计算选中区域的长度
    arr.forEach((opt: any) => {
      if (opt.text == this.lineicon) {
        hasHuanhang = true;
      }
      strLength += opt.width;
      this.currentSelectText += opt.text;
    });
    if (hasHuanhang) {
      this.$message.warning("暂不支持跨段标注！");
      return;
    }
    if (strLength > 900 - this.defaultLeft - this.defaultRight - 20) {
      this.$message.warning("标注内容过长，请重新选择！");
      return;
    }
    // 如果所选文本跨行了，则需要判断与之关联的所有标注的总长度是否超过最大范围
    this.startIndex = arr[0].index;
    this.endIndex = arr[arr.length - 1].index;
    this.getStartIndex(this.startIndex);
    this.getEndIndex(this.endIndex);
    const Length = getStringWidth(
      this.allString.slice(this.startIndex, this.endIndex + 1),
      this.fontSize,
      this.lineicon
    );
    if (Length > 900 - this.defaultLeft - this.defaultRight - 20) {
      this.$message.warning("相关标注内容总长超出最大值，请重新选择！");
      return;
    }
    //记录文本标注信息，需要在保存的时候发送给后端保存
    this.dialogData = {
      type: "text",
      index: [arr[0].index, arr[arr.length - 1].index], // 标记的开始-结束索引
      text: this.currentSelectText, // 标记的文本
      关联实体: {},
      attrs: [],
      语义类型: {}, // 语义类型
      备注: "",
    };
    //生成id(标注id)
    // if (this.biaozhuData.length == 0) {
    //   this.dialogData.id = 1;
    // } else {
    //   this.dialogData.id = this.biaozhuData[this.biaozhuData.length - 1].id + 1;
    // }
    this.dialogData.id = new Date().getTime();
    this.openBiaozhuText();
  }
  /**
   * @description 计算选中文本的行数，对行的判断原理不一样，不能随便用
   * @description 这里文本下方差值以上都算作当前行,最后一行以下算作最后一行
   */
  private getNewLine(lineData: any, y: any) {
    let line: any = 1;
    // 计算行数
    lineData.forEach((ele: any, index: any) => {
      if (
        index > 0 &&
        y < ele.newRange[1] &&
        y > lineData[index - 1].newRange[1]
      ) {
        line = index + 1;
      }
    });
    // 特殊处理最后一行，最后一行以下算作最后一行
    if (lineData.length > 1 && y > lineData[lineData.length - 1].newRange[1]) {
      line = lineData.length;
    }
    return line;
  }
  // 根据始终点循坏原始数据生成符合条件的集合
  private getSelectArr(startPoint: any, endPoint: any) {
    let startLine: any = startPoint.line;
    let endLine: any = endPoint.line;
    let startX: any = startPoint.x;
    let startY: any = startPoint.e.offsetY;
    let endX: any = endPoint.x;
    let endY: any = endPoint.e.offsetY;
    const wucha: any = 14; // 这是上下误差值
    let arr: any = []; // 选中中本数组
    // 重新生成行数据。按照文字上方误差值位置到下行文字上方误差值位置为当前行
    let newLineData: any = JSON.parse(JSON.stringify(this.lineData));
    newLineData.forEach((ele: any) => {
      // 如果是换行符需要特殊处理
      if (ele.text == "\n") {
        ele.newRange = [ele.top - 1, ele.top];
      } else {
        ele.newRange = [ele.top - this.fontSize - wucha, ele.top + wucha];
      }
    });
    // 根据新生成的行数据计算开始和结束点的所在行。
    startLine = this.getNewLine(newLineData, startPoint.e.offsetY);
    endLine = this.getNewLine(newLineData, endPoint.e.offsetY);
    // 先生成满足条件的line,x1,x2
    let fillData: any = []; // 满足条件的区域
    if (startLine == endLine) {
      //单行
      const cLineRange = newLineData[startLine - 1].newRange;
      let obj: any = {
        line: 0,
        x1: 0,
        x2: 0,
        position: "1-1",
      };
      if (
        startY > cLineRange[0] &&
        startY < cLineRange[1] &&
        endY > cLineRange[0] &&
        endY < cLineRange[1]
      ) {
        //开始结束都在可选中区域
        // 根据起点和终点不同给x轴范围
        if (startX < endX) {
          //鼠标从左到右
          fillData.push({
            line: startLine,
            x1: startX,
            x2: endX,
            position: "1-2",
          });
        } else {
          //鼠标从右到左
          fillData.push({
            line: startLine,
            x1: endX,
            x2: startX,
            position: "1-3",
          });
        }
      } else if (startY > cLineRange[0] && startY < cLineRange[1]) {
        //起点在里面
        fillData.push({
          line: startLine,
          x1: 0,
          x2: startX,
          position: "1-4",
        });
      } else if (endY > cLineRange[0] && endY < cLineRange[1]) {
        //终点在里面
        fillData.push({
          line: startLine,
          x1: 0,
          x2: endX,
          position: "1-5",
        });
      }
    } else {
      // 多行
      // 需要考虑起点行，终点行和中间行
      if (
        ((startY < newLineData[startLine - 1].newRange[0] ||
          startY > newLineData[startLine - 1].newRange[1]) &&
          endY < newLineData[endLine - 1].newRange[0]) ||
        endY > newLineData[endLine - 1].newRange[1]
      ) {
        // 首末行都在区域外
        //小行选中全部，大行不用管
        if (startLine < endLine) {
          fillData.push({
            line: startLine,
            x1: 0,
            x2: this.W,
            position: "2-1",
          });
        } else {
          fillData.push({
            line: endLine,
            x1: 0,
            x2: this.W,
            position: "2-2",
          });
        }
      } else if (
        startY > newLineData[startLine - 1].newRange[0] &&
        startY < newLineData[startLine - 1].newRange[1] &&
        endY > newLineData[endLine - 1].newRange[0] &&
        endY < newLineData[endLine - 1].newRange[1]
      ) {
        // 首末行都在区域内
        //小行选中右边，大行选中左边
        if (startLine < endLine) {
          fillData.push({
            line: startLine,
            x1: startX,
            x2: this.W,
            position: "2-3",
          });
          fillData.push({
            line: endLine,
            x1: 0,
            x2: endX,
            position: "2-4",
          });
        } else {
          fillData.push({
            line: endLine,
            x1: endX,
            x2: this.W,
            position: "2-5",
          });
          fillData.push({
            line: startLine,
            x1: 0,
            x2: endX,
            position: "2-6",
          });
        }
      } else if (
        startY > newLineData[startLine - 1].newRange[0] &&
        startY < newLineData[startLine - 1].newRange[1]
      ) {
        // 起点或者终点在内
        if (startLine < endLine) {
          //
          fillData.push({
            line: startLine,
            x1: startX,
            x2: this.W,
            position: "2-7",
          });
        } else {
          fillData.push({
            line: endLine,
            x1: endX,
            x2: this.W,
            position: "2-8",
          });
        }
      } else if (
        endY > newLineData[endLine - 1].newRange[0] &&
        endY < newLineData[endLine - 1].newRange[1]
      ) {
        // 起点或者终点在内
        if (startLine < endLine) {
          //
          fillData.push({
            line: startLine,
            x1: 0,
            x2: this.W,
            position: "2-9",
          });
          fillData.push({
            line: endLine,
            x1: 0,
            x2: endX,
            position: "2-10",
          });
        } else {
          fillData.push({
            line: endLine,
            x1: endX,
            x2: this.W,
            position: "2-11",
          });
        }
      }
      // 生成中间行数据
      if (Math.abs(startLine - endLine) > 1) {
        // 中间行都满足条件
        // 确定大小行
        if (startLine < endLine) {
          for (let i = startLine + 1; i < endLine; i++) {
            fillData.push({
              line: i,
              x1: 0,
              x2: this.W,
              position: "3-1",
            });
          }
        } else {
          for (let i = endLine + 1; i < startLine; i++) {
            fillData.push({
              line: i,
              x1: 0,
              x2: this.W,
              position: "3-2",
            });
          }
        }
      }
    }
    this.strArrData.forEach((ele: any, eleIndex: any) => {
      const index = fillData.findIndex((item: any) => {
        return (
          item.line == ele.line &&
          ele.startX > item.x1 - 4 &&
          ele.startX < item.x2
        );
      });
      if (index != -1) {
        arr.push(ele);
      }
    });

    return arr;
  }
  private computeLinePath(start: any, end: any, lineOffsetY: any = 0) {
    return `M${start.x} ${start.y} Q${start.x + (end.x - start.x) / 2} ${
      end.y * 0.8
    } ${end.x} ${end.y}`;
  }
  // 根据y坐标计算所在行
  private getLineNum(y: any) {
    let line: any = 0;
    this.lineData.forEach((item: any, itemIndex: any) => {
      if ((y > item.range[0] || y == item.range[0]) && y < item.range[1]) {
        line = item.line;
      }
    });
    return line;
  }
  /**
   * @description 清除所有绘图,有参数是清除某部分
   * @param val,不传就是清除全部，传入对应的index,就是清除固定的。
   * 0:typeDom //语义类型标注（文字，底色）
   * 1:selectDom //滑动选中文本的底色
   * 2:textsDom,// 文本内容
   * 3:lineDom,//画关系时鼠标滑动的连线
   * 4:relationDom,//关系的连线
   * 5:lineTextDom,// 关系类型的文字和底色
   * 6:shijianDom,// 事件标注（文字，底色）
   * 7:highlightDom,// 查看时高亮关系连线
   * 8:highlightLineDom,// 查看时高亮关系文字底色
   */
  private clearAll(val?: number) {
    const typeDom: any = document.getElementById("typeDom");
    const selectDom: any = document.getElementById("select");
    const textsDom: any = document.getElementById("texts");
    const lineDom: any = document.getElementById("line");
    const relationDom: any = document.getElementById("lineDom");
    const lineTextDom: any = document.getElementById("lineText");
    const shijianDom: any = document.getElementById("shijianDom");
    const highlightDom: any = document.getElementById("highlightDom");
    const highlightLineDom: any = document.getElementById("highlightLineDom");

    let typeChild: any = typeDom.childNodes;
    const selectChild: any = selectDom.childNodes;
    const textsChild: any = textsDom.childNodes;
    const lineChild: any = lineDom.childNodes;
    const relationChild: any = relationDom.childNodes;
    const lineTextChild: any = lineTextDom.childNodes;
    const shijianChild: any = shijianDom.childNodes;
    const highlightChild: any = highlightDom.childNodes;
    const highlightLineDomChild: any = highlightLineDom.childNodes;

    let domArr: any = [
      typeDom,
      selectDom,
      textsDom,
      lineDom,
      relationDom,
      lineTextDom,
      shijianDom,
      highlightDom,
      highlightLineDom,
    ];
    let nodeArr: any = [
      typeChild,
      selectChild,
      textsChild,
      lineChild,
      relationChild,
      lineTextChild,
      shijianChild,
      highlightChild,
      highlightLineDomChild,
    ];
    if (val || val === 0) {
      if (nodeArr[val] && nodeArr[val].length > 0) {
        for (var i = nodeArr[val].length - 1; i >= 0; i--) {
          domArr[val].removeChild(nodeArr[val][i]);
        }
      }
    } else {
      nodeArr.forEach((nodes: any, index: any) => {
        if (nodes && nodes.length > 0) {
          for (var i = nodes.length - 1; i >= 0; i--) {
            domArr[index].removeChild(nodes[i]);
          }
        }
      });
    }
    this.$forceUpdate();
  }
  /**
   * @description 清除绘图
   * @param arr 每一项都是biaozhuResult里面的内容，这里面比较全
   * 0:typeDom //语义类型标注（文字，底色）
   * 1:selectDom //滑动选中文本的底色
   * 2:textsDom,// 文本内容
   * 3:lineDom,//画关系时鼠标滑动的连线
   * 4:relationDom,//关系的连线
   * 5:lineTextDom,// 关系类型的文字和底色
   * 6:shijianDom,// 事件标注（文字，底色）
   * 7:highlightDom,// 查看时高亮关系连线
   */
  private clearOne(arr: any) {
    const typeDom: any = document.getElementById("typeDom");
    const selectDom: any = document.getElementById("select");
    const textsDom: any = document.getElementById("texts");
    const lineDom: any = document.getElementById("line");
    const relationDom: any = document.getElementById("lineDom");
    const lineTextDom: any = document.getElementById("lineText");
    const shijianDom: any = document.getElementById("shijianDom");
    const highlightDom: any = document.getElementById("highlightDom");
    let domArr: any = [
      typeDom,
      selectDom,
      textsDom,
      lineDom,
      relationDom,
      lineTextDom,
      shijianDom,
      highlightDom,
    ];
    arr.forEach((data: any) => {
      // 先提取出类型和索引,有可能会有2个，比如关系的时候
      let dom1: any = Number(data.drawId.split("-")[0]);
      let dom2: any;
      for (let i = 0; i < data.drawLength; i++) {
        const num = Number(data.drawId.split("-")[1]) + i;
        Array.from(domArr[dom1].childNodes).forEach(
          (node: any, nodeIndex: any) => {
            // 确保node是Element类型（即，是SVG或HTML元素）
            if (
              node.nodeType === Node.ELEMENT_NODE &&
              node.id === dom1 + "-" + num
            ) {
              // 从父元素中移除子元素
              domArr[dom1].removeChild(node);
              // 由于我们只打算删除一个具有指定ID的元素，一旦找到并删除，就可以退出循环
              return;
            }
          }
        );
      }
      if (data.drawId2) {
        dom2 = data.drawId2.split("-")[0];
        for (let i = 0; i < data.drawLength2; i++) {
          const num = Number(data.drawId2.split("-")[1]) + i;
          Array.from(domArr[dom2].childNodes).forEach(
            (node: any, nodeIndex: any) => {
              // 确保node是Element类型（即，是SVG或HTML元素）
              if (
                node.nodeType === Node.ELEMENT_NODE &&
                node.id === dom2 + "-" + num
              ) {
                // 从父元素中移除子元素
                domArr[dom2].removeChild(node);
                // 由于我们只打算删除一个具有指定ID的元素，一旦找到并删除，就可以退出循环
                return;
              }
            }
          );
        }
      }
    });
    this.$forceUpdate();
  }
  private resetData() {
    this.linePath = "";
    this.startPoint = {};
    this.endPoint = {};
    this.lineData = [];
    this.startIndex = 0;
    this.endIndex = 0;
    this.drawing = false;
    this.startDrawLine = false;
    this.currentSelectText = "";
    this.ifShowBiaozhuLineDialog = false;
    this.ifShowBiaozhuTextDialog = false;
    this.optionsData = [];
    this.drawineData = {
      to: {},
      from: {},
      关系类型: {},
      备注: "",
    };
    this.dialogData = {
      语义类型: {},
    };
    this.clickData = {
      from: { data: {} },
      to: { data: {} },
    };
    this.$forceUpdate();
  }
  private getStartIndex(index: any) {
    let curIndex = index;
    let hasRange: any = false;
    this.biaozhuData.forEach((item: any, itemIndex: any) => {
      if (
        item.type == "text" &&
        curIndex > item.index[0] &&
        curIndex <= item.index[1] &&
        curIndex > item.index[0]
      ) {
        curIndex = item.index[0];
        this.startIndex = item.index[0];
        hasRange = true;
      }
    });

    if (hasRange) {
      this.getStartIndex(curIndex);
    }
  }
  private getEndIndex(index: any) {
    let curIndex = index;
    let hasRange: any = false;
    this.biaozhuData.forEach((item: any, itemIndex: any) => {
      if (
        item.type == "text" &&
        curIndex >= item.index[0] &&
        curIndex < item.index[1] &&
        curIndex < item.index[1]
      ) {
        curIndex = item.index[1];
        this.endIndex = item.index[1];
        hasRange = true;
      }
    });

    if (hasRange) {
      this.getEndIndex(curIndex);
    }
  }
  // 生成单个字符串的字符串数组
  private getStrArr() {
    this.allString = this.data;
    this.strArrData = [];
    let arr = splitData([this.allString], this.lineicon);
    let line: any = 1;
    let x: any = this.defaultLeft;
    this.lineData = [
      { line: 1, num: 0, top: 0, range: [], 标注: [], text: "" },
    ];
    arr.forEach((val: any, i: any) => {
      if (val.length < 1) {
        return;
      }
      if (line !== this.lineData[this.lineData.length - 1].line) {
        this.lineData.push({
          line: line,
          num: 0,
          top: 0,
          range: [],
          标注: [],
          text: val,
        });
      } else {
        this.lineData[this.lineData.length - 1].text += val;
      }
      // 获取字符串文本长度
      let width = getStringWidth(val, this.fontSize, this.lineicon);
      // 生成基础信息数组strArrData，存取基础信息
      if (val.length === 1) {
        const strWidth: any = getStringWidth(val, this.fontSize, this.lineicon);
        if (this.strArrData.length === 0) {
          const strObj: any = {
            index: 0,
            text: val,
            width: strWidth,
            line: line,
            startX: x, // 开始x轴坐标
          };
          if (val == this.lineicon) {
            strObj.width = 0;
          }
          this.strArrData.push(strObj);
        } else {
          const strLast: any = this.strArrData[this.strArrData.length - 1];
          const strObj: any = {
            index: strLast.index + 1,
            text: val,
            width: strWidth,
            line: line,
            startX: strLast.startX + strLast.width, // 开始x轴坐标
          };
          if (val == this.lineicon) {
            strObj.width = 0;
          }
          if (strLast.line != line) {
            strObj.startX = x;
          }
          this.strArrData.push(strObj);
        }
      } else {
        val.split("").forEach((text: any) => {
          const strWidth: any = getStringWidth(
            text,
            this.fontSize,
            this.lineicon
          );
          if (this.strArrData.length === 0) {
            const strObj: any = {
              index: 0,
              text: text,
              width: strWidth,
              line: line,
              startX: x, // 开始x轴坐标
            };
            if (text == this.lineicon) {
              strObj.width = 0;
            }
            this.strArrData.push(strObj);
          } else {
            const strLast: any = this.strArrData[this.strArrData.length - 1];
            const strObj: any = {
              index: strLast.index + 1,
              text: text,
              width: strWidth,
              line: line,
              startX: strLast.startX + strLast.width, // 开始x轴坐标
            };
            if (text == this.lineicon) {
              strObj.width = 0;
            }
            if (strLast.line != line) {
              strObj.startX = x;
            }
            this.strArrData.push(strObj);
          }
        });
      }
      // 计算下一条数据
      // 非最后一项长度超过屏幕内容宽度或者下一个元素是换行符,需要换行
      // 如果下一个有标注，且长度会超过屏幕或者中间有换行符也需要换行
      const strLast: any = this.strArrData[this.strArrData.length - 1];
      let isBiaozhuHuanhang: any = false;
      if (this.biaozhuData.length > 0) {
        let indexRange: any = [];
        this.biaozhuData.forEach((item: any, itemIndex: any) => {
          if (item.type == "text" && item.index[0] == strLast.index + 1) {
            if (indexRange.length === 0) {
              indexRange = JSON.parse(JSON.stringify(item.index));
            }
          }
        });
        let W: any = 0;
        if (indexRange.length > 0) {
          this.endIndex = indexRange[1];
          this.getEndIndex(this.endIndex);
          indexRange[1] = this.endIndex;
          W = getStringWidth(
            this.allString.slice(indexRange[0], indexRange[1] + 1),
            this.fontSize,
            this.lineicon
          );
        }
        if (x + W > this.W - this.defaultRight) {
          isBiaozhuHuanhang = true;
        }
      }
      if (
        i !== arr.length - 1 &&
        (x + getStringWidth(arr[i + 1], this.fontSize, this.lineicon) >
          this.W - this.defaultRight ||
          val == this.lineicon ||
          isBiaozhuHuanhang)
      ) {
        line += 1;
        x = this.defaultLeft;
      } else {
        x += width;
      }
    });
  }
  // 处理标记数据，形成可直接绘制标记的数据
  private getBiaojiArr() {
    this.biaozhuResult = [];
    if (this.biaozhuData.length == 0) {
      return;
    }
    let arr: any = [];
    this.biaozhuData.forEach((item: any, index: any) => {
      // 先循环处理出类型标注的内容
      if (item.type == "text" && !item.ifCustom) {
        let obj: any = JSON.parse(JSON.stringify(item));
        // 计算出语义类型需要展示的文本，如果关联了实体，最多展示关联实体前5个字
        obj.drawText = item["语义类型"]["label"];
        if (item["关联实体"] && item["关联实体"].name) {
          if (item["关联实体"].name.length < 6) {
            obj.drawText += "(" + item["关联实体"].name + ")";
          } else {
            obj.drawText +=
              "(" + item["关联实体"].name.substring(0, 5) + "...)";
          }
        }

        let range: any = []; // 整个标记的最大x轴范围
        // 计算标记的最大范围的起始坐标,如果选中文本比标记文本长则用选中文本的，否则用标记文本的，此处是用来判断是否会重合，从而标记加一行
        const w: any = getStringWidth(item.text, this.fontSize, this.lineicon); // 被选中文本的长度
        let w1: any =
          getStringWidth(obj.drawText, this.biaozhuFontSize, this.lineicon) + 4; // 语义类型标注的长度，4是大约标记边框的大小留余地
        const x: any = this.strArrData[item.index[0]].startX; //被选中文本的起始x坐标
        const x1: any = x + w / 2 - w1 / 2; // 语义类型标记的起始X坐标
        if (w < w1) {
          // 标记的最大范围的起始坐标用语义类型标注的
          range = [x1, x1 + w1];
        } else {
          // 标记的最大范围的起始坐标用选中文字的
          range = [x, x + w];
        }
        obj.width = w; // 所选文本的长度
        obj.startX = x; // 所选文本的起始X坐标
        obj.drawTextW = w1; // 语义类型标注的长度
        obj.drawTextX = x1; // 语义类型标注的起始X坐标
        obj.range = range; // 整个标记的最大x轴范围(原则是选中文本和标注文本取长的)

        const line: any = this.strArrData[item.index[0]].line;
        obj.line = line; //  所选文字行数,取的是选中文本的第一个文字所在行

        arr.push(obj);
      } else if (item.type == "event" && !item.ifCustom) {
        let obj: any = JSON.parse(JSON.stringify(item));
        let text: any = "";
        if (item["事件关联实体"] == "新建") {
          text = item["事件名称"];
        } else {
          text = item["关联实体"].name;
        }
        // 如果超过5个字，则只显示5个字
        if (text.length < 6) {
          obj.drawText = item["事件类型"]["语义定义名称"] + "(" + text + ")";
        } else {
          obj.drawText =
            item["事件类型"]["语义定义名称"] +
            "(" +
            text.substring(0, 5) +
            "...)";
        }
        // 计算最终需要绘制的事件标注文字的宽度
        obj.drawTextW = getStringWidth(
          obj.drawText,
          this.biaozhuFontSize,
          this.lineicon
        );
        obj.range = [obj.drawTextX, obj.drawTextX + obj.drawTextW]; // 标注的x轴范围
        obj.line = item.position.line;
        arr.push(obj);
      } else if (item.type == "line" && !item.ifCustom) {
        let obj: any = JSON.parse(JSON.stringify(item));
        // 关系的文本长度
        let text: any = "";
        if (obj["关系类型"]["名称"]) {
          text = obj["关系类型"]["名称"];
        } else if (obj["关系类型"]["label"]) {
          text = obj["关系类型"]["label"];
        } else if (obj["关系类型"]["relation"]) {
          text = obj["关系类型"]["relation"];
        }
        obj.drawText = text;
        obj.drawTextW = getStringWidth(
          obj.drawText,
          this.biaozhuFontSize,
          this.lineicon
        );
        // 根据from和to的id，完善from和to的数据
        obj.from = arr[this.getIndex(obj.from.id, arr)];
        obj.to = arr[this.getIndex(obj.to.id, arr)];
        // 生成关系标注所在行数
        // 哪个类型标注的行数偏小类型标注就展示在哪行
        if (obj.from.line > obj.to.line) {
          obj.line = obj.to.line;
        } else {
          obj.line = obj.from.line;
        }
        // 找出from和to哪个在左边，哪个在右边便于计算，关系标注的展示位置是在to和from的中间
        let left: any = {};
        let right: any = {};
        if (obj.from.drawTextX > obj.to.drawTextX) {
          left = obj.to;
          right = obj.from;
        } else {
          left = obj.from;
          right = obj.to;
        }
        // 根据left和right生成关系文本的x范围
        const center: any =
          left.drawTextX +
          (right.drawTextX + right.drawTextW - left.drawTextX) / 2; //left和right的中心点
        // 生成关系文本的开始x坐标
        obj.drawTextX = center - obj.drawTextW / 2;
        // 比较关系文本和from与to之间的距离哪个大，range使用大的做参考值,20是关系类型左右至少需要10的画线距离
        let range: any = [0, 0];
        if (
          right.drawTextX + right.drawTextW - left.drawTextX >
          obj.drawTextW + 20
        ) {
          range = [left.drawTextX, right.drawTextX + right.drawTextW];
        } else {
          range = [
            center - obj.drawTextW / 2 - 10,
            center + obj.drawTextW / 2 + 10,
          ];
        }
        obj.range = range;
        obj.lineW = range[1] - range[0];

        arr.push(obj);
      }
    });
    // 给标记排列,保证不能重叠
    // biaozhuArr是一个双重数组，每一行为数组的一项，比如biaozhuArr[0]是所有在第一行的标注，这里的行是指标注的行，挨着文字的是第一行，不是text的行
    const biaozhuArr: any = [[]];
    // 先吧事件的sort处理出来放到biaozhuArr，因为事件的位置是不能移动的，
    // 不考虑事件与事件的重叠
    arr.forEach((item: any, itemIndex: any) => {
      if (item.type == "event") {
        // 事件需要根据position和oneLineBiaozhuH来计算位置。
        // 如果事件是在文末，这里不会有别的标注，就不需要做处理，直接sort给0就行
        if (
          item.position.offset > 0 &&
          item.position.line == this.lineData.length
        ) {
          item.sort = 0;
        } else {
          // 如果不是在文末，且offset<-this.fontsize,就是在空行。sort直接给1就行
          if (
            item.position.line != this.lineData.length &&
            item.position.offset > -this.fontSize - 1
          ) {
            item.sort = 1;
          } else {
            // 计算sort
            const num = -item.position.offset - this.fontSize;
            item.sort = Math.ceil(num / this.oneLineBiaozhuH);
            for (var i = 0; i < item.sort; i++) {
              if (biaozhuArr.length < i + 1) {
                biaozhuArr.push([]);
              }
            }
            biaozhuArr[item.sort - 1].push(item);
          }
        }
      }
    });

    arr.forEach((item: any, itemIndex: any) => {
      // 生成关系标注和类型标注的行数
      if (item.type == "text" || item.type == "line") {
        let sort: any = 1;
        if (arr.length !== 0) {
          // 同行已经有标记，且和当前区域有重合需要加一行
          for (var i = 0; i < biaozhuArr.length; i++) {
            let rep: any = false;
            for (var j = 0; j < biaozhuArr[i].length; j++) {
              const ifRepeat: any = isIntersect(
                item.range,
                biaozhuArr[i][j].range
              );
              if (ifRepeat && item.line == biaozhuArr[i][j].line) {
                rep = true;
              }
            }
            if (!rep) {
              if (sort > i + 1) {
                sort = i + 1;
              }
            } else {
              if (sort == i + 1) {
                sort = i + 2;
              }
            }
          }
        }
        item.sort = sort;
        if (item.sort > biaozhuArr.length) {
          biaozhuArr.push([item]);
        } else {
          biaozhuArr[sort - 1].push(item);
        }
        if (this.lineData[item.line - 1]["标注"][sort - 1]) {
          this.lineData[item.line - 1]["标注"][sort - 1].push(item);
        } else {
          this.lineData[item.line - 1]["标注"].push([item]);
        }
      } else if (item.type == "event") {
        if (item.sort > 0) {
          if (this.lineData[item.line - 1]["标注"][item.sort - 1]) {
            this.lineData[item.line - 1]["标注"][item.sort - 1].push(item);
          } else {
            this.lineData[item.line - 1]["标注"].push([item]);
          }
        }
      }
      this.biaozhuResult.push(item);
    });
  }
  // 获取某行标注的高度
  private getBiaozhuHeight(arr: any) {
    return arr.length * this.oneLineBiaozhuH;
  }
  // 最终生成行数据,getStrArr会生成line字段，getBiaojiArr会生成标注字段，这里是生成剩下的top和range字段
  private getLineData() {
    this.lineData.forEach((ele: any, index: any) => {
      if (index === 0) {
        let top: any = this.fontSize + this.getBiaozhuHeight(ele["标注"]);
        // 当标注行数超过1行时就不需要给默认的加大行间距
        if (ele["标注"].length < 2) {
          top += this.defaultTop;
        } else {
          top += 10;
        }
        ele.top = top;
        if (this.lineData.length > 1) {
          let nextSpace: any =
            (this.getBiaozhuHeight(this.lineData[index + 1]["标注"]) +
              this.fontSize) /
            2;
          // 当标注行数超过1行时就不需要给默认的加大行间距
          if (this.lineData[index + 1]["标注"].length < 2) {
            nextSpace += this.defaultTop;
          } else {
            nextSpace += 20;
          }
          ele.range = [0, top + nextSpace];
        } else {
          ele.range = [0, top + 40];
        }
      } else if (index == this.lineData.length - 1) {
        const before: any = this.lineData[index - 1];
        let top: any =
          before.top + this.fontSize + this.getBiaozhuHeight(ele["标注"]);
        // 当标注行数超过1行时就不需要给默认的加大行间距
        if (ele["标注"].length < 2) {
          top += this.defaultTop;
        } else {
          top += 10;
        }
        ele.top = top;
        ele.range = [before.range[1], top + 40];
      } else {
        const before: any = this.lineData[index - 1];
        let top: any =
          before.top + this.fontSize + this.getBiaozhuHeight(ele["标注"]);
        // 当标注行数超过1行时就不需要给默认的加大行间距
        if (ele["标注"].length < 2) {
          top += this.defaultTop;
        } else {
          top += 10;
        }
        let nextSpace: any =
          (this.getBiaozhuHeight(this.lineData[index + 1]["标注"]) +
            this.fontSize) /
          2;
        // 当标注行数超过1行时就不需要给默认的加大行间距
        if (this.lineData[index + 1]["标注"].length < 2) {
          nextSpace += this.defaultTop;
        } else {
          nextSpace += 10;
        }
        ele.top = top;
        ele.range = [before.range[1], top + nextSpace];
      }
    });
    this.contentHeight = this.lineData[this.lineData.length - 1].top + 40;
    // 根据行数据生成部分绘制数据
    this.biaozhuResult.forEach((item: any, index: any) => {
      // 当前标注前面的标注，用来算y轴数据的，因为关系标注和语义类型标注占的高度不一样
      let beforeBiaozhuHeight: any = this.getBiaozhuHeight(
        this.lineData[item.line - 1]["标注"].slice(0, item.sort - 1)
      );
      if (item.type == "text") {
        // 生成跟y轴有关的数据
        item.startY = this.lineData[item.line - 1].top;
        item.drawTextY =
          item.startY -
          this.fontSize -
          // this.getBiaozhuHeight(item['标注'])
          beforeBiaozhuHeight -
          16; //  16是下面的括号
        item.content = [
          // 用来判断是不是点击在了语义类型上面，生成的参考坐标
          //2,4是为标注不要挨太近
          [item.drawTextX - 2, item.drawTextX + item.drawTextW + 4],
          [
            item.drawTextY - this.fontSize - 2,
            item.drawTextY - this.fontSize + this.fontSize + 4,
          ],
        ];
      } else if (item.type == "event") {
        // 生成drawTextY
        // 这里加this.fontSize是因为添加事件点击的坐标是文字左上角的y,而文字的绘制y是左下角，所以这里需要处理下
        item.drawTextY =
          this.lineData[item.position.line - 1].top +
          item.position.offset +
          this.fontSize;
        item.content = [
          // 用来判断是不是点击在了语义类型上面，生成的参考坐标
          //2,4是为标注不要挨太近
          [item.drawTextX - 2, item.drawTextX + item.drawTextW + 4],
          [
            item.drawTextY - this.fontSize - 2,
            item.drawTextY - this.fontSize + this.fontSize + 4,
          ],
        ];
      } else if (item.type == "line") {
        // 生成drawTextY
        // 如果from和to有时间标注，则需要按照哪个在上面按照哪个的y坐标确定第二个点和第三个点的位置
        if (item.from.type == "event" || item.to.type == "event") {
          if (item.from.drawTextY > item.to.drawTextY) {
            item.drawTextY = item.to.drawTextY - this.biaozhuFontSize - 16;
          } else {
            item.drawTextY = item.from.drawTextY - this.biaozhuFontSize - 16;
          }
        } else {
          item.drawTextY =
            this.lineData[item.line - 1].top -
            this.fontSize -
            beforeBiaozhuHeight -
            16; //16是为了跟语义类型保持统一做的处理，语义类型16是下面的括号，在这里就是会留部分空白
        }

        // 生成content
        item.content = [
          // 用来判断是不是点击在了语义类型上面，生成的参考坐标
          //2,4是为标注不要挨太近
          [item.drawTextX - 2, item.drawTextX + item.drawTextW + 4],
          [
            item.drawTextY - this.biaozhuFontSize - 2,
            item.drawTextY - this.biaozhuFontSize + this.biaozhuFontSize + 4,
          ],
        ];
        // 生成画关系线的四个点point1(from)，point2，point3，point4(to)
        // 需要判断from是在页面的左边还是to在页面的左边，因为线始终是左边标注的左点和右边标注的右点，结束位置会有箭头
        if (item.from.drawTextX > item.to.drawTextX) {
          // from在右边
          item.point1 = {
            x: item.from.drawTextX + item.from.drawTextW,
            y: item.from.drawTextY - this.biaozhuFontSize,
          };
          item.point2 = {
            x: item.range[1],
            y: item.drawTextY - this.biaozhuFontSize / 2, //画在字的中间
          };
          item.point3 = {
            x: item.range[0],
            y: item.drawTextY - this.biaozhuFontSize / 2, //画在字的中间
          };
          item.point4 = {
            x: item.to.drawTextX,
            y: item.to.drawTextY - this.biaozhuFontSize,
          };
        } else {
          // to在右边
          item.point1 = {
            x: item.from.drawTextX,
            y: item.from.drawTextY - this.biaozhuFontSize,
          };
          item.point2 = {
            x: item.range[0],
            y: item.drawTextY - this.biaozhuFontSize / 2, //画在字的中间
          };
          item.point3 = {
            x: item.range[1],
            y: item.drawTextY - this.biaozhuFontSize / 2, //画在字的中间
          };
          item.point4 = {
            x: item.to.drawTextX + item.to.drawTextW,
            y: item.to.drawTextY - this.biaozhuFontSize,
          };
        }
      }
    });
  }
  // 渲染文本内容
  private createText() {
    const element: any = document.getElementById("texts");
    // 渲染文本内容
    this.strArrData.forEach((ele: any, i: any) => {
      if (ele.text == this.lineicon) {
        return;
      }
      drawText(
        element,
        ele.startX,
        this.lineData[ele.line - 1].top,
        ele.text,
        this.fontSize,
        "#333"
      );
      this.strPointArr.push({
        text: ele.text,
        width: getStringWidth(ele.text, this.fontSize, this.lineicon),
        x: ele.startX,
        y: this.lineData[ele.line - 1].top,
        line: ele.line,
      });
    });
  }
  private createContent() {
    if (!this.data) {
      return;
    }
    this.resetData();
    // 需要重新绘制的时候需要清除之前的所有路径
    this.clearAll();
    // 生成单个字符串的字符串数组
    this.getStrArr();
    // 处理标记数据，形成可直接绘制标记的数据
    this.getBiaojiArr();
    // 生成行数据
    this.getLineData();
    this.createText();
    // 绘制标注
    this.drawBiaozhu();
  }
  /**
   * 通过id拿标注对应的index
   * id,需要查找的id字段
   * arr,需要查找的数组,可以不传，不传就在this.biaozhuData里面寻找
   */
  private getIndex(id: any, arr?: any) {
    let data: any = [];
    if (arr) {
      data = arr;
    } else {
      data = this.biaozhuData;
    }
    let i = -1;
    data.forEach((ele: any, index: any) => {
      if (ele.id === id) {
        i = index;
      }
    });
    return i;
  }
  private getTreeOption(val: any) {
    const params: any = {
      kind: val,
    };
    if (val == "关系类型") {
      if (this.drawineData.from.type == "text") {
        params.from_text = this.drawineData.from["语义类型"].label;
      } else if (this.drawineData.from.type == "event") {
        params.from_text = this.drawineData.from["事件类型"]["语义定义名称"];
      }
      if (this.drawineData.to.type == "text") {
        params.to_text = this.drawineData.to["语义类型"].label;
      } else if (this.drawineData.to.type == "event") {
        params.to_text = this.drawineData.to["事件类型"]["语义定义名称"];
      }

      delete params.kind;
    }
    GetBiaozhuOption(this, params).then((res: any) => {
      if (val == "语义类型") {
        this.yuyiData = res;
      } else {
        // 需要选上默认值
        res.forEach((ele: any) => {
          ele.relation = ele["类别"];
        });
        this.guanxiOptions = res;
        // 循环找出有没有推荐的
        if (this.dialogType == "新建") {
          let tuijian: any = [];
          res.forEach((element: any) => {
            element.children.forEach((ele: any) => {
              if (ele.rec) {
                tuijian.push(ele);
              }
            });
          });
          this.tuijianData = tuijian;
          // if (tuijian.length > 0) {
          //   this.drawineData["关系类型"] = {
          //     _id: tuijian[0].id,
          //     label: tuijian[0].relation,
          //   };
          // }
        }
        this.openBiaozhuLine();
      }
      this.$forceUpdate();
    });
  }
  private getLineOption() {
    const params: any = {
      kind: "关系类型",
    };
    GetBiaozhuOption(this, params).then((res: any) => {
      this.guanxiOptions = res;
    });
  }
  private mounted() {
    const W = (this.$refs.biaozhuBox as any).offsetWidth;
    if (W > 900) {
      this.W = W;
    } else {
      this.W = 900;
    }
    this.getTreeOption("语义类型");
    // 禁掉浏览器的选中文字效果
    document.onselectstart = function () {
      return false;
    };
    this.createContent();
  }
}
