





























































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { GetShitiList, AddShiti } from "@/request/storehouse";
import { GetChuxianyuList, GetChuxianyuList2 } from "@/request/mark";
import { GetShuxinglaiyuanOptions } from "@/request/check";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    Empty,
  },
})
export default class Name extends Vue {
  @Prop()
  private id: any;
  @Prop()
  private type: any;
  @Prop()
  private detail: any;
  @Watch("detail", { immediate: true })
  private detailChange() {
    if (!this.cTab) {
      this.cTab = this.detail["专题类型列表"][0]["专题类型"];
    }
    this.getShuxing();
  }
  // @Prop()
  // private refreshDataTime: any;
  // @Watch("refreshDataTime", { immediate: true })
  // private refreshDataTimeChange() {
  //   this.getShuxing();
  // }
  private cTab: any = "";
  private guanxiArr: any = [];
  private data1: any = {
    attributes: [],
  };
  private data2: any = {
    attributes: [],
  };
  private curSourceData: any = {};
  private ifShowSource: any = false;
  private curSource: any = ""; //添加来源页面的值
  private sources: any = [];
  private guanxiKeys: any = {};
  private guanxiData: any = {};
  private navKeys: any = []; //主题标签的导航
  private shuxingData: any = [];
  private navData: any = {};
  private ifEditName: any = false;
  private editData: any = {};
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private changeTab(item: any) {
    this.cTab = item;
    this.getShuxing();
  }
  private ifShowTip(text: any) {
    if (!text) {
      return false;
    }
    if (this.detail.content.includes(text)) {
      return false;
    } else {
      return true;
    }
  }
  private getShitiText(arr: any) {
    if (arr.length == 0) {
      return "/";
    } else {
      let str: any = "";
      arr.forEach((ele: any, index: any) => {
        if (index > 0) {
          str += "、";
        }
        if (ele.id) {
          str += ele.name;
        } else if (ele.text) {
          str += ele.text;
        }
      });
      return str;
    }
  }
  private addZidingyiShiti() {
    const index: any = this.detail["专题类型列表"].findIndex((val: any) => {
      return val["专题类型"] == this.cTab;
    });
    this.$emit("addZidingyiShiti", {
      table_name: this.cTab,
      id: this.detail["专题类型列表"][index].entity_id,
      need_attr_split: true,
      一级主题: this.cTab,
    });
  }
  private addShiti(item: any) {
    item["一级主题"] = this.cTab;
    this.$emit("addShiti", item);
  }
  private editNameSure() {
    if (!this.editData.text) {
      this.$message.warning("请输入实体名称");
      return;
    }
    this.$emit("editShitiName", this.editData);
    this.ifEditName = false;
  }
  // 处理左键单击的方法
  private editName(item: any) {
    if (item.ifCustom) {
      this.$emit("editOneShiti", item);
      // this.editData = JSON.parse(JSON.stringify(item));
      // this.ifEditName = true;
    } else {
      this.$confirm(
        "您修改的实体来源于左侧文本，修改后原来左侧标注的文本及其关系会同步消除，您确定修改么？",
        "修改实体名称",
        {
          customClass: "commonConfirm",
        }
      ).then(() => {
        // this.editData = JSON.parse(JSON.stringify(item));
        // this.ifEditName = true;
        this.$emit("editOneShiti", item);
      });
    }
  }
  // 处理右键单击的方法
  private handleRightClick(itemD: any, event: MouseEvent) {
    const item=JSON.parse(JSON.stringify(itemD));
    event.preventDefault(); // 阻止默认的右键菜单显示
    if (!this.ifCompose) {
      return;
    }
    item["一级主题"] = this.cTab;
    item["实体关系"] = [];
    item.options = {
      keys: [],
    };
    // 需要生成语义类型和实体的选项。
    this.detail.data.forEach((eleD: any) => {
      const ele = JSON.parse(JSON.stringify(eleD));
      // 只需要处理语义类型
      if (ele.type == "text" && ele["二级主题"] && ele["一级主题"]) {
        // 只需要过滤当前以及主题下面的
        if (ele["一级主题"] == item["一级主题"]) {
          // 不能自己跟自己建立关系，所以需要过滤当前实体
          if (ele.id !== item.id) {
            const index = item.options.keys.findIndex((val: any) => {
              return val.label == ele["语义类型"].label;
            });
            if (index == -1) {
              item.options.keys.push(ele["语义类型"]);
              item.options[ele["语义类型"].label] = [ele];
            } else {
              item.options[ele["语义类型"].label].push(ele);
            }
          }
        }
      }
    });

    // 需要生成展示的关系
    let relations: any = [];
    let originRelations: any = [];
    // 只需要在当前二级主题、三级语义类型的关系里面过滤
    let cRelations: any = [];
    const key: any = item["语义类型"].label + "关系";
    if (
      this.navData[item["二级主题"]] &&
      this.navData[item["二级主题"]][key] &&
      this.navData[item["二级主题"]][key].length > 0
    ) {
      cRelations = JSON.parse(
        JSON.stringify(this.navData[item["二级主题"]][key])
      );
    }
    cRelations.forEach((ele: any) => {
      // from和to有一个是当前实体就需要拿出来
      // from和to任意一个满足都需要过滤出来
      let obj: any = {};
      if (ele.from.id == item.id || ele.to.id == item.id) {
        originRelations.push(JSON.parse(JSON.stringify(ele)));
        if (ele.from.id == item.id) {
          obj.data = JSON.parse(JSON.stringify(ele));
          obj.ifCustom = ele.ifCustom;
          // obj.left = ele.fromData;
          obj.right = [ele.toData];
          obj.direction = "right";
        } else if (ele.to.id == item.id) {
          obj.ifCustom = ele.ifCustom;
          obj.data = JSON.parse(JSON.stringify(ele));
          // obj.left = ele.toData;
          obj.right = [ele.fromData];
          obj.direction = "left";
        }
        // 判断有没有重复的，如果有添加进去，如果没有新增一个
        // 重复原则：方向，关系类型，语义类型,ifCustom相同
        let repeat: any = false;
        let repeatIndex: any = 0;
        relations.forEach((d: any, resultIndex: any) => {
          //相同方向相同
          if (d.direction == obj.direction) {
            //关系类型相同
            if (d.data["关系类型"].id == obj.data["关系类型"].id) {
              //语义类型相同
              if (d["语义类型"]._id == obj.right[0]["语义类型"]._id) {
                if (d.ifCustom == obj.ifCustom) {
                  // 这些字段全部重复才算重复
                  repeat = true;
                  repeatIndex = resultIndex;
                }
              }
            }
          }
        });
        if (repeat) {
          // 存在，需要添加进去
          relations[repeatIndex].right.push(obj.right[0]);
        } else {
          obj["语义类型"] = obj.right[0]["语义类型"];
          relations.push(obj);
        }
      }
    });
    item["实体关系"] = relations;
    item.originRelations = originRelations;
    this.$emit("editOne", item);
  }
  private delOne(e: any) {
    this.$confirm("确定删除么？", "删除", {
      customClass: "commonConfirm",
    }).then(() => {
      if (e.ifCustom) {
        // 删除手动添加的
        let d: any = JSON.parse(JSON.stringify(this.detail));
        // 删除关系标注
        d.data.forEach((item: any, i: any) => {
          if (item.type == "line") {
            if (item.from.id == e.id || item.to.id == e.id) {
              d.data.splice(i, 1);
            }
          }
        });
        // 删除当前标注
        const eIndex: any = d.data.findIndex((val: any) => {
          return val.id == e.id;
        });
        d.data.splice(eIndex, 1);
        this.$forceUpdate();
        this.$emit("changeData", d);
      } else {
        this.$emit("delOneBiaozhi", e);
      }
    });
  }
  // 这个只删除左边添加的
  // private delShiti(zhuti: any, key: any) {
  //   this.$confirm("确定删除当前语义类型下所有标注实体和关系么？", "删除", {
  //     customClass: "commonConfirm",
  //   }).then(() => {
  //     const arr: any = [];
  //     let detail: any = JSON.parse(JSON.stringify(this.detail));
  //     detail.data.forEach((ele: any) => {
  //       // 只需要处理语义类型
  //       if (ele.type == "text") {
  //         // 判断是不是需要展示的语义类型
  //         let type = ele["语义类型"]["label"];
  //         if (key == type && ele["二级主题"] == zhuti) {
  //           // 换顺序，因为删除时index会变，所以换顺序后相当于是从后往前删
  //           arr.unshift(ele);
  //         }
  //       }
  //     });
  //     // 避免删除关系的时候找不到from或者to,需要先把所有关系删除，再统一删除实体
  //     // 删除关系
  //     arr.forEach((d: any) => {
  //       let index: any = detail.data.findIndex((val: any) => {
  //         return val.id == d.id;
  //       });
  //       if (d.type === "text") {
  //         // 删除相关的关系标注
  //         detail.data.forEach((item: any, i: any) => {
  //           if (item.type == "line") {
  //             if (item.from.id == d.id || item.to.id == d.id) {
  //               // 如果是通过右边主题标签里面添加的关系因为没有绘制路径，只需要清除数组里面的内容，无需清除绘制路径
  //               if (item.ifCustom) {
  //                 detail.data.splice(i, 1);
  //               }
  //             }
  //           }
  //         });
  //       }
  //     });
  //     // 删除实体
  //     arr.forEach((d: any) => {
  //       let shitiIndex: any = detail.data.findIndex((val: any) => {
  //         return val.id == d.id;
  //       });
  //       detail.data.splice(shitiIndex, 1);
  //     });
  //     this.$forceUpdate();
  //     // return;
  //     this.$emit("changeData", detail);
  //   });
  // }
  // 删除某个语义类型下面所有实体
  private delShiti(zhuti: any, key: any) {
    this.$confirm("确定删除当前语义类型下所有标注实体和关系么？", "删除", {
      customClass: "commonConfirm",
    }).then(() => {
      const d: any = [];
      this.detail.data.forEach((ele: any) => {
        // 只需要处理语义类型
        // 只需要处理语义类型
        if (ele.type == "text") {
          // 判断是不是需要展示的语义类型
          let type = ele["语义类型"]["label"];
          if (key == type && ele["二级主题"] == zhuti) {
            // 换顺序，因为删除时index会变，所以换顺序后相当于是从后往前删
            d.unshift(ele);
          }
        }
      });
      this.$emit("delMore", d);
    });
  }
  private addOne(item: any) {
    item.push({
      text: "",
      source: "",
    });
  }
  /**
   * @description 有关联实体就返回关联实体，没有就是text
   * @param obj 需要过滤的元数据
   */
  private getText(obj: any) {
    if (!obj) {
      return "";
    }
    let text: any = "";
    if (!obj || !obj["关联实体"] || !obj["关联实体"].name) {
      text = obj.text;
    } else {
      text = obj["关联实体"].name;
    }
    return text;
  }
  private getDataFromIndex(id: any) {
    const index: any = this.detail.data.findIndex((ele: any) => {
      return ele.id == id;
    });
    return this.detail.data[index];
  }
  private deleteSource(item: any) {
    if (!this.ifCompose) {
      return;
    }
    item.source = "";
  }
  private deleteOne(item: any, i: any) {
    item.splice(i, 1);
  }
  private openSource(item: any) {
    this.curSourceData = item;
    this.ifShowSource = true;
    this.curSource = "";
  }
  private addSource() {
    this.curSourceData.source = this.curSource;
    this.getSources();
    this.ifShowSource = false;
  }
  private remoteSource(e: any) {
    this.getSources(e);
  }
  private getShuxing() {
    if (!this.cTab || !this.id) {
      return;
    }
    const index: any = this.detail["专题类型列表"].findIndex((val: any) => {
      return val["专题类型"] == this.cTab;
    });
    const params: any = {
      params: {
        table_name: this.cTab,
        id: this.detail["专题类型列表"][index].entity_id,
        // id: this.id,
        need_attr_split: true,
      },
    };
    GetShitiList(this, params).then((res: any) => {
      this.shuxingData = res;
      this.refreshGuanxi();
    });
  }
  private refreshGuanxi() {
    this.navData = {
      keys: [],
    };
    // 清空生成的语义类型，重新生成
    this.shuxingData.attributes.forEach((ele: any) => {
      this.navData.keys.push(ele.label);
      this.navData[ele.label] = {
        keys: [],
      };
    });
    this.detail.data.forEach((ele: any) => {
      // 只需要处理语义类型
      if (ele.type == "text" && ele["二级主题"] && ele["一级主题"]) {
        // 判断是不是需要展示的二级主题
        if (this.navData[ele["二级主题"]] && this.cTab == ele["一级主题"]) {
          let type = ele["语义类型"]["label"];
          // 如果对应的语义类型没有，则需要添加
          if (!this.navData[ele["二级主题"]][type]) {
            this.navData[ele["二级主题"]].keys.push(type);
            this.navData[ele["二级主题"]][type] = [];
            this.navData[ele["二级主题"]][type + "关系"] = [];
          }
          // 找出对应的关系放进去
          let relations: any = [];
          this.detail.data.forEach((single: any) => {
            // 只需要找关系
            if (single.type == "line") {
              // from和to任意一个满足都需要过滤出来
              const fromData: any = this.getDataFromIndex(single.from.id);
              const toData: any = this.getDataFromIndex(single.to.id);
              const fromText: any = this.getText(fromData);
              const toText: any = this.getText(toData);
              let obj: any = {
                direction: "",
              };
              // from和to任意一个满足都需要过滤出来
              if (single.from.id == ele.id || single.to.id == ele.id) {
                if (single.from.id == ele.id) {
                  obj.data = single;
                  obj.left = fromData;
                  obj.right = toData;
                  obj.direction = "right";
                } else if (single.to.id == ele.id) {
                  obj.data = single;
                  obj.left = toData;
                  obj.right = fromData;
                  obj.direction = "left";
                }
                if (!obj.right["关联实体"] || !obj.right["关联实体"].id) {
                  obj.right["关联实体"] = {
                    text: obj.right.text,
                  };
                }
                // 判断有没有重复的，如果有添加进去，如果没有新增一个
                // 重复原则：方向，关系类型，语义类型
                let repeat: any = false;
                let repeatIndex: any = 0;
                relations.forEach((d: any, resultIndex: any) => {
                  //相同方向相同
                  if (d.direction == obj.direction) {
                    //关系类型相同
                    if (
                      d["关系类型"] &&
                      obj.data["关系类型"] &&
                      d["关系类型"].id == obj.data["关系类型"].id
                    ) {
                      //语义类型相同
                      if (
                        d["语义类型"] &&
                        d["语义类型"]._id == obj.right["语义类型"]._id
                      ) {
                        // 这些字段全部重复才算重复
                        repeat = true;
                        repeatIndex = resultIndex;
                      }
                    }
                  }
                });
                if (repeat) {
                  // 存在，需要添加进去
                  // 如果有关联实体，需要添加进去，需要判断重复，重复了就不用添加了
                  if (obj.right["关联实体"] && obj.right["关联实体"].id) {
                    // 判断是否已经存在
                    const shitiIndex: any = relations[repeatIndex][
                      "关联实体"
                    ].findIndex((val: any) => {
                      return val.id == obj.right["关联实体"].id;
                    });
                    if (shitiIndex == -1) {
                      relations[repeatIndex]["关联实体"].push(
                        obj.right["关联实体"]
                      );
                    }
                  }
                } else {
                  // 不存在，添加一个
                  const one: any = {
                    direction: obj.direction,
                    关系类型: obj.data["关系类型"],
                    语义类型: obj.right["语义类型"],
                    关联实体: [],
                    text: obj.right.text,
                  };
                  if (obj.right["关联实体"]) {
                    one["关联实体"].push(obj.right["关联实体"]);
                  }
                  relations.push(one);
                }
              }
            }
          });
          // 加入一个
          ele.relations = relations;
          this.navData[ele["二级主题"]][type].push(ele);
        }
      }
      // 处理关系，需要一级主题、二级主题、三级语义类型都对上
      if (
        ele.type == "line" &&
        ele["二级主题"] &&
        ele["一级主题"] &&
        ele["三级语义类型"] &&
        ele["一级主题"] == this.cTab
      ) {
        const key = ele["三级语义类型"] + "关系";
        if (!this.navData[ele["二级主题"]][key]) {
          this.navData[ele["二级主题"]][key] = [];
        }
        let lineObj: any = ele;
        lineObj.fromData = this.getDataFromIndex(ele.from.id);
        lineObj.toData = this.getDataFromIndex(ele.to.id);
        this.navData[ele["二级主题"]][key].push(lineObj);
      }
    });
    console.log("右侧处理后的数据");
    console.log(this.navData);
    this.$forceUpdate();
    console.log("处理后的数据");
    console.log(this.navData);
  }
  private getSources(e?: any) {
    const params: any = {
      params: {
        search: e,
      },
    };
    GetShuxinglaiyuanOptions(this, params).then((res: any) => {
      this.sources = res;
    });
  }
  private tijiao() {
    return new Promise((resolve, reject) => {
      this.data1.texts = "其它";
      AddShiti(this, this.data1)
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  }
}
